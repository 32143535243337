import global_search from '@/apps/admin/store/modules/base/global_search';
import history_and_preview from '@/apps/admin/store/modules/finance/history_and_preview';
import administration from '@/apps/admin/store/modules/administration';
import hotspot_export_report from '@/apps/admin/store/modules/administration/reports/hotspot_export_report';
import menu_store from '@/components/base/XMenu/store';
import global_notifications_store from '@/components/base/XGlobalNotification/store';
import scheduling_map from '@/apps/admin/store/modules/scheduling/scheduling_map';
import createPersistedState from 'vuex-persistedstate';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import search from './modules/search';
import entry_points from './modules/entry_points';
import locations from './modules/locations';
import partners from './modules/partners';
import leads from './modules/leads';
import tasks from './modules/scheduling/tasks';
import sidebar from './modules/base/sidebar';
import notifications from './modules/base/notifications';
import internet_tariffs from './modules/tariffs/internet_tariffs';
import voice_tariffs from './modules/tariffs/voice_tariffs';
import customer_invoices from './modules/customers/billing/customer_invoices';
import customer_credit_notes from './modules/customers/billing/customer_credit_notes';
import acs_provision_flow from './modules/configs/networkign/acs/provision_flow';
import settings from './modules/common/settings';
import customers from './modules/customers/customers';
import inventory from './modules/inventotry/index';
import customer_billing from './modules/customers/billing/customer_billing';
import customer_proforma_invoices from './modules/customers/billing/customer_proforma_invoices';
import templates from './modules/configs/templates';
import labels from './modules/configs/labels';
import customer_payments from './modules/customers/billing/customer_payments';
import customer_statements from './modules/customers/billing/customer_statements';
import customer_transactions from './modules/customers/billing/customer_transactions';
import customer_recurring_invoices from './modules/customers/billing/customer_recurring_invoices';
import finance from './modules/finance';
import on_boarding from './modules/onboarding/onboarding';
import customers_overview from './modules/customers/overview';
import cap_configs from './modules/configs/main/cap/cap_configs';
import portal_configs from './modules/configs/main/portal/portal_configs';
import incoming_mail_boxes from './modules/configs/support/incoming-mail/incoming-mail-boxes';
import admin_profile from './modules/profile/admin_profile';
import admin_imap from './modules/profile/admin_imap';
import admin_customer_search from './modules/profile/admin_customer_search';
import admin_scheduling from './modules/profile/admin_scheduling';

export default {
    state: () => ({
        menuStructure: [],
        admin: {},
        logo: '',
        footer: '',
        license: {},
        serverSideConfig: {},
        pageTitle: '',
        refreshRouteKey: (new Date().getTime()),
        documentationConfig: {},
        showNotFoundPage: false,
    }),
    mutations,
    actions,
    getters,
    modules: {
        search,
        entry_points,
        locations,
        partners,
        leads,
        tasks,
        global_notifications_store,
        sidebar,
        notifications,
        internet_tariffs,
        voice_tariffs,
        customer_invoices,
        customer_credit_notes,
        customers,
        customer_billing,
        acs_provision_flow,
        settings,
        inventory,
        customer_proforma_invoices,
        templates,
        labels,
        customer_payments,
        customer_statements,
        customer_transactions,
        customer_recurring_invoices,
        finance,
        global_search,
        history_and_preview,
        customers_overview,
        on_boarding,
        scheduling_map,
        hotspot_export_report,
        menu_store,
        administration,
        cap_configs,
        portal_configs,
        incoming_mail_boxes,
        admin_profile,
        admin_imap,
        admin_customer_search,
        admin_scheduling,
    },
    plugins: [
        createPersistedState({
            paths: ['notifications'],
            storage: {
                getItem: (key) => window.localStorage.getItem(key),
                setItem: (key, value) => window.localStorage.setItem(key, value),
                removeItem: (key) => window.localStorage.removeItem(key),
            },
        }),
    ],
};
