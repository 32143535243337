import { empty } from '@/utils/functions';
import {
    SET_MENU,
    SET_ADMIN,
    SET_HEADER_SHORTCUTS,
    SET_LOGO,
    SET_LICENSE,
    SET_SERVER_SIDE_CONFIG,
    SET_PAGE_TITLE, REFRESH_ROUTE,
    SET_NUMBER_CONFIG,
    SET_DOCUMENTATION_CONFIGS, SET_FOOTER, SET_ON_BOARDING_STEPS,
    SET_APP_CHILD_REFERENCES,
    SET_LOGOUT_TIMEOUT, SET_ADMIN_PROFILE_PHOTO,
    SET_SHOW_NOT_FOUND_PAGE,
} from './mutation-types';

export default {
    [SET_NUMBER_CONFIG](state, config) {
        state.numberConfig = config;
    },
    [SET_MENU](state, menuStructure) {
        state.menu = menuStructure;
    },
    [SET_ADMIN](state, admin) {
        state.admin = admin;
    },
    [SET_ADMIN_PROFILE_PHOTO](state, url) {
        if (state.admin) {
            state.admin = {
                ...state.admin,
                photo_url: url,
            };
        }
    },
    [SET_HEADER_SHORTCUTS](state, headerShortcuts) {
        state.headerShortcuts = headerShortcuts;
    },
    [SET_LOGO](state, admin) {
        state.logo = admin;
    },
    [SET_FOOTER](state, footer) {
        state.footer = footer;
    },
    [SET_SERVER_SIDE_CONFIG](state, config) {
        state.serverSideConfig = config;
    },
    [SET_LICENSE](state, license) {
        state.license = license;
    },
    [SET_LOGOUT_TIMEOUT](state, time) {
        if (state.admin) {
            state.admin.timeout = time;
        }
    },
    [SET_PAGE_TITLE](state, newTitle) {
        state.pageTitle = newTitle;

        if (!empty(newTitle)) {
            $.address.title(`${window.admin_config_admin_title}: ${newTitle.replace(/&amp;/g, '&')}`);
            $('.page-name').empty().text(newTitle.replace(/&amp;/g, '&'));
        }
    },
    /**
     * Call this mutation for refresh current route view (not all page, only route view).
     * For example call this after "show/hide column" modal.
     * refreshRouteKey used as :key in <router-view>, so when key is changed route will be refreshed.
     * @param state
     */
    [REFRESH_ROUTE](state) {
        state.refreshRouteKey = (new Date()).getTime();
    },
    [SET_DOCUMENTATION_CONFIGS](state, configs) {
        state.documentationConfig = JSON.parse(configs);
    },
    [SET_ON_BOARDING_STEPS](state, onBoardingSteps) {
        state.onBoardingSteps = onBoardingSteps;
    },
    [SET_APP_CHILD_REFERENCES](state, referencesApp) {
        if (typeof state.referencesApp === 'undefined') {
            state.referencesApp = referencesApp;
        } else {
            state.referencesApp = Object.assign(state.referencesApp, referencesApp);
        }
    },
    [SET_SHOW_NOT_FOUND_PAGE](state, value) {
        state.showNotFoundPage = value;
    },
};
