import { stateToGetters } from '@/apps/admin/store/helpers';
import { SET_CAP_CONFIGS_PAGE_DATA } from '@/apps/admin/store/mutation-types';

const state = {
    config: {},
    raw_config: {},
    attributesMeta: {},
    partner_id: null,
    partner: {},
    partners_list: {},
    is_partner_config: false,
    billing_type: '',
    billing_types: {},
    is_need_show_top_up_warning: false,
};

const getters = {
    ...stateToGetters(state),
};

const actions = {
    loadPageData({ commit }, { partner_id, billing_type }) {
        return new Promise((resolve, reject) => {
            if (partner_id === null) {
                partner_id = '';
            }
            $.ajax({
                url: `/admin/config/main/cap--get-page-data?partner_id=${partner_id}&billing_type=${billing_type}`,
                success: (response) => {
                    commit(SET_CAP_CONFIGS_PAGE_DATA, response);
                    resolve();
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    },
};

const mutations = {
    [SET_CAP_CONFIGS_PAGE_DATA](state, data) {
        for (let k in data) {
            state[k] = data[k];
        }
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
