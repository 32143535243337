import XTwigPage from '@/components/base/XTwigPage';

const BundlePlansList = () => import(/* webpackChunkName: "bundle_plans" */ '@/apps/admin/views/admin/tariffs/BundlePlansList');

export default [
    {
        path: '',
        name: 'admin.tariffs.bundles',
        component: BundlePlansList,
        meta: {
            breadcrumbs: [
                { title: ['common', 'Tariffs plans'], url: '/admin/tariffs/bundles' },
                { title: ['common', 'Bundles'], url: '/admin/tariffs/bundles' },
            ],
            title: ['tariffs', 'Bundles'],
            keepAlive: true,
            componentName: 'BundlePlansList',
        },
    },
    {
        delimiter: '--', // Use -- instead of / as path delimiter. Custom prop. Not from vue-router.
        path: 'add',
        name: 'admin.tariffs.bundles.add',
        component: XTwigPage,
    },
    {
        delimiter: '--',
        path: 'edit',
        name: 'admin.tariffs.bundles.edit',
        component: XTwigPage,
    },
];
