<template>
    <div class="sidebar-content-inner">
        <x-preloader-block :loading="loading">
            <h4 v-show="links.length > 0">
                {{ $t('common', 'Recommended steps') }}
            </h4>
            <ul
                v-show="links.length > 0"
                class="list-unstyled mb-12"
            >
                <li
                    v-for="(link, index) in links"
                    :key="index"
                    class="mt-12"
                >
                    <a
                        :href="link.url"
                        class="btn btn-link"
                        :title="link.title"
                        @click.prevent="clickHandler(link.url)"
                        v-text="link.title"
                    />
                    <a
                        v-popover:tooltip.top="link.docTitle"
                        :href="link.docUrl"
                        target="_blank"
                        class="icon-wrap color-secondary"
                        @click.prevent="clickDocHandler(link.docUrl)"
                    >
                        <i class="icon-ic_fluent_book_information_24_regular" />
                    </a>
                </li>
            </ul>
            <h4>{{ $t('common', 'Additional steps') }}</h4>
            <ul class="list-unstyled mb-12 mt-12">
                <li>
                    <a
                        href="https://deploy.splynx.com/"
                        target="_blank"
                        class="btn btn-link"
                        @click.prevent="clickDocHandler('https://deploy.splynx.com/')"
                    >
                        {{ $t('common', 'Watch our tutorial videos for ease of deployment') }}
                    </a>
                </li>
            </ul>
            <div class="video">
                <iframe
                    :src="'https://www.youtube.com/embed/UtE4oifGqZ8'"
                    frameborder="0"
                    width="100%"
                    height="275px"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                />
            </div>
            <ul class="list-unstyled mb-12 mt-12">
                <li>
                    <a
                        href="https://wiki.splynx.com/"
                        target="_blank"
                        class="btn btn-link"
                        @click.prevent="clickDocHandler('https://wiki.splynx.com/')"
                    >
                        {{ $t('common', 'Read detailed product documentation') }}
                    </a>
                </li>
            </ul>
            <div class="row">
                <div class="col-md-12">
                    <a
                        href=""
                        class="btn btn-primary pull-right"
                        :title="t('common', 'Don\'t show this guide again')"
                        @click="hideGuide"
                        v-text="t('common', 'Don\'t show this guide again')"
                    />
                </div>
            </div>
        </x-preloader-block>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import XPreloaderBlock from '@/components/common/XPreloaderBlock/XPreloaderBlock';

export default {
    name: 'XDeploymentGuide',
    components: { XPreloaderBlock },
    data() {
        const docsConfig = JSON.parse(window.spl_config.documentationConfig);
        return {
            loading: false,
            defaultLinks: [
                {
                    title: t('common', 'Configure the system'),
                    url: '/admin/config',
                    docTitle: t('common', 'Read about how configure the system'),
                    docUrl: `${docsConfig.doc_url}configuration`,
                    permissionKey: 'config',
                },
                {
                    title: t('common', 'Configure network'),
                    url: '/admin/networking/routers/list',
                    docTitle: t('common', 'Read about how configure network'),
                    docUrl: `${docsConfig.doc_url}networking`,
                    permissionKey: 'routers_list',
                },
                {
                    title: t('common', 'Add tariff plan'),
                    url: '/admin/tariffs/internet--add',
                    docTitle: t('common', 'Read about how add tariff plan'),
                    docUrl: `${docsConfig.doc_url}configuring_tariff_plans`,
                    permissionKey: 'internet_tariffs_add',
                },
                {
                    title: t('common', 'Add customer'),
                    url: '/admin/customers/add',
                    docTitle: t('common', 'Read about how add customer'),
                    docUrl: `${docsConfig.doc_url}customer_management/add_new_customer`,
                    permissionKey: 'customers_add',
                },
                {
                    title: t('common', 'Add router'),
                    url: '/admin/networking/routers/add',
                    docTitle: t('common', 'Read about how add router'),
                    docUrl: `${docsConfig.doc_url}networking/routers_settings`,
                    permissionKey: 'routers_add',
                },
            ],
        };
    },
    created() {
        this.loading = true;
        this.loadPermissions()
            .then(() => {
                this.loading = false;
            })
            .catch((err) => {
                console.error(err);
                this.loading = false;
            });
    },
    computed: {
        ...mapGetters('on_boarding', [
            'permissionsMap',
        ]),
        links() {
            return this.defaultLinks.filter((el) => (isset(this.permissionsMap, el.permissionKey) && this.permissionsMap[el.permissionKey]));
        },
    },
    methods: {
        clickHandler(url) {
            this.hideSidebar();
            switch_page(url);
        },
        // All opens links by handler besides splynx/web/js/development/vue.js:7504
        clickDocHandler(url) {
            window.open(url, '_blank');
        },
        hideGuide() {
            this.hideDeploymentGuide();
            this.hideSidebar();
        },
        ...mapActions('sidebar', [
            'hideSidebar',
        ]),
        ...mapActions('on_boarding', [
            'hideDeploymentGuide',
            'loadPermissions',
        ]),
    },
};
</script>
