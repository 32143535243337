/**
 * Init show modals handlers for showModal() calls
 * @param self
 */
function init(self) {
    onCloseModal((name) => {
        self.$modals.dismiss(name);
    });

    onShowModal('profile-edit-photo', (params) => {
        self.$modals.open({
            name: 'profile-edit-photo',
            props: {
                name: 'profile-edit-photo',
                title: self.$t('profile', 'Edit photo'),
                width: 663,
                height: 550,
                closeButton: {
                    text: self.$t('profile', 'Close'),
                    id: 'admin_administration_profile_photo_form_close_button',
                },
                submitButton: {
                    text: self.$t('profile', 'Save'),
                    id: 'admin_administration_profile_photo_form_close_button',
                },
            },
            onSubmit: params.successCallback,
            component: () => import('@/components/dialogs/profile/ProfileChangePhoto'),
        });
    });

    onShowModal('canned-group-form', (params) => {
        createReloadDataTableEvent('#profile_canned_responses_group_list');
        let title = self.$t('support', 'Edit');
        let submit = self.$t('support', 'Save');
        if (!params.id) {
            title = self.$t('support', 'Create');
            submit = self.$t('support', 'Add');
        }
        self.$modals.open({
            name: 'canned-group-form',
            props: {
                name: 'canned-group-form',
                title,
                id: params.id,
                width: 500,
                height: 350,
                closeButton: {
                    text: self.$t('support', 'Close'),
                    id: 'admin_profile_support_ticket_canned_groups_form_close_button',
                },
                submitButton: {
                    text: submit,
                    id: 'admin_profile_support_ticket_canned_groups_form_submit_button',
                },
            },
            onSubmit: () => {
                reloadLastDataTable();
            },
            component: () => import('@/components/dialogs/profile/CannedGroupForm'),
        });
    });

    onShowModal('canned-group-delete', (params) => {
        createReloadDataTableEvent('#profile_canned_responses_group_list');
        self.$modals.open({
            name: 'canned-group-form',
            props: {
                name: 'canned-group-form',
                title: self.$t('support', 'Delete'),
                id: params.id,
                width: 500,
                height: 350,
                closeButton: {
                    text: self.$t('support', 'Close'),
                    id: 'admin_profile_support_ticket_canned_group_delete_form_close_button',
                },
                submitButton: {
                    text: self.$t('support', 'Delete'),
                    id: 'admin_profile_support_ticket_canned_group_delete_form_submit_button',
                },
            },
            onSubmit: () => {
                reloadLastDataTable();
            },
            component: () => import('@/components/dialogs/profile/CannedGroupDelete'),
        });
    });

    onShowModal('canned-responses-form', (params) => {
        createReloadDataTableEvent('#profile_canned_responses_list');
        let title = self.$t('support', 'Edit');
        let submit = self.$t('support', 'Save');
        if (!params.id) {
            title = self.$t('support', 'Create');
            submit = self.$t('support', 'Add');
        }
        self.$modals.open({
            name: 'canned-responses-form',
            props: {
                name: 'canned-responses-form',
                title,
                id: params.id,
                width: 850,
                height: 600,
                closeButton: {
                    text: self.$t('support', 'Close'),
                    id: 'admin_profile_support_ticket_canned_responses_form_close_button',
                },
                submitButton: {
                    text: submit,
                    id: 'admin_profile_support_ticket_canned_responses_form_submit_button',
                },
            },
            onSubmit: () => {
                reloadLastDataTable();
            },
            component: () => import('@/components/dialogs/profile/CannedResponsesForm'),
        });
    });

    onShowModal('canned-responses-delete', (params) => {
        createReloadDataTableEvent('#profile_canned_responses_list');
        self.$modals.open({
            name: 'canned-responses-delete',
            props: {
                name: 'canned-responses-delete',
                title: self.$t('support', 'Delete'),
                id: params.id,
                width: 500,
                height: 350,
                closeButton: {
                    text: self.$t('support', 'Close'),
                    id: 'admin_profile_support_ticket_canned_responses_delete_form_close_button',
                },
                submitButton: {
                    text: self.$t('support', 'Delete'),
                    id: 'admin_profile_support_ticket_canned_responses_delete_form_submit_button',
                },
            },
            onSubmit: () => {
                reloadLastDataTable();
            },
            component: () => import('@/components/dialogs/profile/CannedResponseDelete'),
        });
    });

    onShowModal('two-factor-auth-form', (params) => {
        self.$modals.open({
            name: 'two-factor-auth-form',
            props: {
                name: 'two-factor-auth-form',
                title: self.$t('profile', 'Two-factor authentication configuration'),
                width: 500,
                height: 550,
                closeButton: {
                    text: self.$t('profile', 'Close'),
                },
            },
            onSubmit: params.successCallback,
            component: () => import('@/components/dialogs/profile/OtpConfigForm'),
        });
    });

    onShowModal('delete-customer-rate-table', (params) => {
        self.$modals.open({
            name: 'delete-customer-rate-table-modal',
            props: {
                title: params.title,
                url: `/admin/voice/rates/customers--delete?id=${params.id}`,
                deleteBtnId: 'admin_voice_customer_rate_tables_delete_button_submit',
                closeBtnId: 'admin_voice_customer_rate_tables_delete_button_close',
                confirmMessage: self.$t('voice', 'Are you sure want to delete rate table <b>"{name}"</b>?', { name: params.title }),
            },
            component: () => import('@/components/dialogs/ModalDelete'),
            onClose: () => {
                $('#admin_voice_rates_customer_rate_tables_list').DataTable().ajax.reload();
            },
        });
    });

    onShowModal('sell-inventory-items', (params) => {
        self.$modals.open({
            name: 'sell-inventory-items',
            props: {
                ids: params.ids,
                deleteBtnId: 'admin_inventory_items_sold_button_submit',
                closeBtnId: 'admin_inventory_items_sold_button_button_close',
            },
            component: () => import('@/apps/admin/views/admin/inventory/dialogs/ModalSellItems'),
        });
    });

    onShowModal('add-customer-invoice', (params) => {
        self.$modals.open({
            name: 'finance-document-modal',
            props: {
                customer_id: params.customer_id,
                title: self.$t('finance', 'Add one-time invoice'),
                isNew: true,
                formUrl: `/admin/customers/billing/invoices--add?id=${params.customer_id}`,
                className: 'Invoices',
                requestController: '/admin/customers/billing/invoices',
            },
            component: () => import('@/components/dialogs/customers/billing/invoices/CustomerInvoiceModal'),
            onClose: () => {
                reloadLastDataTable();
            },
        });
    });

    onShowModal('edit-customer-invoice', (params) => {
        self.$modals.open({
            name: 'finance-document-modal',
            props: {
                invoice_id: params.invoice_id,
                customer_id: params.customer_id,
                title: self.$t('finance', 'Edit invoice'),
                isNew: false,
                formUrl: `/admin/customers/billing/invoices--edit?id=${params.customer_id}&invoice_id=${params.invoice_id}`,
                className: 'Invoices',
                requestController: '/admin/customers/billing/invoices',
            },
            component: () => import('@/components/dialogs/customers/billing/invoices/CustomerInvoiceModal'),
            onClose: () => {
                reloadLastDataTable();
            },
        });
    });

    onShowModal('add-customer-proforma-invoice', (params) => {
        self.$modals.open({
            name: 'finance-document-modal',
            props: {
                customer_id: params.customer_id,
                title: self.$t('finance', 'Add proforma invoice'),
                isNew: true,
                formUrl: `/admin/customers/billing/proforma-invoices--add?id=${params.customer_id}`,
                className: 'ProformaInvoices',
                requestController: '/admin/customers/billing/proforma-invoices',
            },
            component: () => import('@/components/dialogs/customers/billing/proforma-invoices/ProformaInvoiceModal'),
            onClose: () => {
                reloadLastDataTable();
            },
        });
    });

    onShowModal('edit-customer-proforma-invoice', (params) => {
        self.$modals.open({
            name: 'finance-document-modal',
            props: {
                request_id: params.request_id,
                customer_id: params.customer_id,
                title: self.$t('finance', 'Edit proforma invoice'),
                isNew: false,
                formUrl: `/admin/customers/billing/proforma-invoices--edit?id=${params.customer_id}&request_id=${params.request_id}`,
                className: 'ProformaInvoices',
                requestController: '/admin/customers/billing/proforma-invoices',
            },
            component: () => import('@/components/dialogs/customers/billing/proforma-invoices/ProformaInvoiceModal'),
            onClose: () => {
                reloadLastDataTable();
            },
        });
    });

    onShowModal('add-customer-payment', (params) => {
        self.$modals.open({
            name: 'finance-document-modal',
            props: {
                customer_id: params.customer_id,
                invoice_id: params.invoice_id,
                request_id: params.request_id,
                title: self.$t('finance', 'Add payment'),
                isNew: true,
                formUrl: `/admin/customers/billing/payments--add?id=${params.customer_id}`,
                className: 'Payments',
            },
            component: () => import('@/components/dialogs/customers/billing/payment/PaymentModal'),
            onClose: () => {
                reloadLastDataTable();
            },
        });
    });

    onShowModal('edit-customer-payment', (params) => {
        self.$modals.open({
            name: 'finance-document-modal',
            props: {
                payment_id: params.payment_id,
                customer_id: params.customer_id,
                title: self.$t('finance', 'Edit payment'),
                isNew: false,
                formUrl: `/admin/customers/billing/payments--edit?id=${params.customer_id}&payment_id=${params.payment_id}`,
                className: 'Payments',
            },
            component: () => import('@/components/dialogs/customers/billing/payment/PaymentModal'),
            onClose: () => {
                reloadLastDataTable();
            },
        });
    });

    onShowModal('view-customer-payment', (params) => {
        self.$modals.open({
            name: 'view-customer-payment',
            props: {
                payment_id: params.payment_id,
                customer_id: params.customer_id,
                title: self.$t('finance', 'View payment'),
                className: 'Payments',
            },
            component: () => import('@/components/dialogs/customers/billing/payment/ViewPaymentModal'),
        });
    });

    onShowModal('view-customer-invoice', (params) => {
        self.$modals.open({
            name: 'view-customer-invoice',
            props: {
                invoice_id: params.invoice_id,
                customer_id: params.customer_id,
                title: self.$t('finance', 'View invoice'),
                className: 'Invoices',
            },
            component: () => import('@/components/dialogs/customers/billing/invoices/ViewInvoiceModal'),
        });
    });

    onShowModal('view-customer-proforma-invoice', (params) => {
        self.$modals.open({
            name: 'view-customer-proforma-invoice',
            props: {
                proforma_invoice_id: params.proforma_invoice_id,
                customer_id: params.customer_id,
                title: self.$t('finance', 'View proforma invoice'),
                className: 'ProformaInvoices',
            },
            component: () => import('@/components/dialogs/customers/billing/proforma-invoices/ViewProformaInvoiceModal'),
        });
    });

    onShowModal('view-customer-credit-note', (params) => {
        self.$modals.open({
            name: 'view-customer-credit-note',
            props: {
                credit_note_id: params.credit_note_id,
                customer_id: params.customer_id,
                title: self.$t('finance', 'View credit note'),
                className: 'CreditNotes',
            },
            component: () => import('@/components/dialogs/customers/billing/credit-notes/ViewCreditNoteModal.vue'),
        });
    });

    onShowModal('customer-generate-statement', (params) => {
        self.$modals.open({
            name: 'finance-document-modal',
            props: {
                customer_id: params.customer_id,
                title: self.$t('customers', 'Generate statement'),
                formUrl: '/admin/administration/reports/statements',
            },
            component: () => import('@/components/dialogs/customers/billing/documents/GenerateStatementModal'),
            onClose: () => {
                reloadLastDataTable();
            },
        });
    });

    onShowModal('add-customer-transaction', (params) => {
        self.$modals.open({
            name: 'finance-document-modal',
            props: {
                customer_id: params.customer_id,
                title: self.$t('finance', 'Add transaction'),
                isNew: true,
                formUrl: `/admin/customers/billing/transactions--add?id=${params.customer_id}`,
                className: 'Transactions',
            },
            component: () => import('@/components/dialogs/customers/billing/transaction/TransactionModal'),
            onClose: () => {
                reloadLastDataTable();
            },
        });
    });

    onShowModal('edit-customer-transaction', (params) => {
        self.$modals.open({
            name: 'finance-document-modal',
            props: {
                customer_id: params.customer_id,
                transaction_id: params.transaction_id,
                title: self.$t('finance', 'Edit transaction'),
                isNew: false,
                formUrl: `/admin/customers/billing/transactions--edit?id=${params.customer_id}&transaction_id=${params.transaction_id}`,
                className: 'Transactions',
            },
            component: () => import('@/components/dialogs/customers/billing/transaction/TransactionModal'),
            onClose: () => {
                reloadLastDataTable();
            },
        });
    });

    onShowModal('add-customer-recurring-invoice', (params) => {
        self.$modals.open({
            name: 'finance-document-modal',
            props: {
                customer_id: params.customer_id,
                title: self.$t('finance', 'Add recurring invoice'),
                formUrl: `/admin/customers/billing/overview--add?id=${params.customer_id}`,
                className: 'Overview',
                requestController: '/admin/customers/billing/invoices',
            },
            component: () => import('@/components/dialogs/customers/billing/invoices/recurring/RecurringModal'),
            onClose: () => {
                reloadLastDataTable();
            },
        });
    });

    onShowModal('add-customer-credit-note', (params) => {
        self.$modals.open({
            name: 'finance-document-modal',
            props: {
                customer_id: params.customer_id,
                credit_note_id: params.credit_note_id,
                title: self.$t('finance', 'Add credit note'),
                isNew: true,
                formUrl: `/admin/customers/billing/credit-notes--add?id=${params.customer_id}`,
                className: 'CreditNotes',
                showLoadTransactions: true,
                requestController: '/admin/customers/billing/credit-notes',
                autoSelectionForInvoiceId: params.select_invoice_id,
            },
            component: () => import('@/components/dialogs/customers/billing/credit-notes/CustomerCreditNoteModal'),
            onClose: () => {
                reloadLastDataTable();
            },
        });
    });

    onShowModal('edit-customer-credit-note', (params) => {
        self.$modals.open({
            name: 'finance-document-modal',
            props: {
                credit_note_id: params.credit_note_id,
                customer_id: params.customer_id,
                title: self.$t('finance', 'Edit credit note'),
                isNew: false,
                formUrl: `/admin/customers/billing/credit-notes--edit?id=${params.customer_id}&credit_note_id=${params.credit_note_id}`,
                className: 'CreditNotes',
                showLoadTransactions: true,
                requestController: '/admin/customers/billing/credit-notes',
            },
            component: () => import('@/components/dialogs/customers/billing/credit-notes/CustomerCreditNoteModal'),
            onClose: () => {
                reloadLastDataTable();
            },
        });
    });

    onShowModal('add-customer-future-item', (params) => {
        self.$modals.open({
            name: 'finance-document-modal',
            props: {
                customer_id: params.customer_id,
                title: self.$t('finance', 'Add future items'),
                formUrl: `/admin/customers/billing/transactions--mass-create-future-items?id=${params.customer_id}`,
            },
            component: () => import('@/components/dialogs/customers/billing/transaction/FutureItemsModal'),
            onClose: () => {
                reloadLastDataTable();
            },
        });
    });

    onShowModal('finance-invoices-mass-pay', (params) => {
        self.$modals.open({
            name: 'finance-document-modal',
            props: {
                ids: params.ids,
                title: self.$t('finance', 'Pay invoices'),
                urlPaymentForm: '/admin/customers/billing/payments--add',
                urlMassPaymentForm: '/admin/customers/billing/payments--mass-add-for-invoice',
                nameSocket: 'finance_invoices_mass_pay',
                nameController: 'invoices',
            },
            component: () => import('@/components/dialogs/finance/mass-actions/PaymentModal'),
            onClose: () => {
                reloadLastDataTable();
            },
        });
    });

    onShowModal('finance-proforma-invoices-mass-pay', (params) => {
        self.$modals.open({
            name: 'finance-document-modal',
            props: {
                ids: params.ids,
                title: self.$t('finance', 'Pay proforma invoices'),
                urlPaymentForm: '/admin/customers/billing/payments--add',
                urlMassPaymentForm: '/admin/customers/billing/payments--mass-add-for-request',
                nameSocket: 'finance_proforma_invoices_mass_pay',
                nameController: 'proforma-invoices',
            },
            component: () => import('@/components/dialogs/finance/mass-actions/PaymentModal'),
            onClose: () => {
                reloadLastDataTable();
            },
        });
    });

    onShowModal('customer-overview-edit-to-bill-log-date', (params) => {
        self.$modals.open({
            name: 'finance-document-modal',
            props: {
                customer_id: params.customer_id,
                title: self.$t('finance', 'Set next block date'),
                formUrl: `/admin/customers/billing/overview--edit-to-bill-log-date?id=${params.customer_id}`,
            },
            component: () => import('@/components/dialogs/customers/overview/EditToBillLogDate'),
        });
    });

    onShowModal('customer-overview-preview-reminder-modal', (params) => {
        self.$modals.open({
            name: 'x-modal',
            props: {
                customerId: params.customerId,
                customerBalance: params.customerBalance,
                minBalance: params.minBalance,
                reminderType: params.reminderType,
                title: self.$t('finance', 'Preview reminder'),
                formUrl: `/admin/customers/billing/overview--preview-reminder?customerId=${params.customerId}&reminderType=${params.reminderType}`,
                nameController: 'overview',
            },
            component: () => import('@/components/dialogs/customers/overview/PreviewReminderModal'),
        });
    });

    onShowModal('confirm-forget-unsaved-data-modal', (params) => {
        self.$modals.open({
            name: 'confirm-action-modal',
            props: {
                ask: params.text,
            },
            component: () => import('@/components/dialogs/common/ConfirmActionDialog'),
            onSubmit: () => {
                params.onSubmit();
            },
            onDismiss: () => {
                params.onDismiss();
            },
        });
    });

    onShowModal('show-statistic-info-modal', (params) => {
        self.$modals.open({
            name: 'show-statistic-info',
            props: params,
            component: () => import('@/components/dialogs/customers/statistics/SessionView'),
        });
    });

    onShowModal('show-customers-license-counters-modal', (params) => {
        self.$modals.open({
            name: 'show-customers-license-counters',
            props: params,
            component: () => import('@/components/dialogs/administration/license/CustomersLicenseCountModal'),
        });
    });

    onShowModal('show-license-usage-details-modal', (params) => {
        self.$modals.open({
            name: 'show-license-usage-details',
            props: params,
            component: () => import('@/components/dialogs/administration/license/LicenseUsageDetails'),
        });
    });

    onShowModal('labels-form-modal', (params) => {
        self.$modals.open({
            name: 'labels-form-modal',
            props: {
                module: params.module,
                id: params.id,
                type: params.type,
            },
            component: () => import('@/components/dialogs/config/LabelsFormModal'),
        });
    });

    onShowModal('labels-form-delete', (params) => {
        self.$modals.open({
            name: 'labels-form-delete',
            props: {
                label: params.label,
                module: params.module,
                id: params.id,
            },
            component: () => import('@/components/dialogs/config/DeleteLabelModal'),
        });
    });

    onShowModal('config-reset-to-default', (params) => {
        self.$modals.open({
            name: 'config-reset-to-default',
            props: params,
            component: () => import('@/components/dialogs/config/ResetToDefaultConfirm'),
        });
    });

    onShowModal('approve-service', (params) => {
        self.$modals.open({
            name: 'approve-service',
            props: {
                id: params.id,
                controller: params.controller,
                startDate: params.start_date,
            },
            component: () => import('@/components/dialogs/customers/services/ApproveService'),
        });
    });

    onShowModal('confirm-domain-dialog-common', (params) => {
        self.$modals.open({
            name: 'confirm-domain-modal',
            props: {
                url: params.url,
                confirmCallback: (domainUrl) => {
                    params.confirm(domainUrl);
                },
            },
            component: () => import('@/apps/admin/views/admin/config/support/incoming-mail/ConfirmDomainDialog'),
        });
    });

    onShowModal('disconnect-google-account', (params) => {
        self.$modals.open({
            name: 'disconnect-google-account-modal',
            props: {
                email: params.email,
                disconnectCallback: () => {
                    params.disconnect();
                },
            },
            component: () => import('@/apps/admin/views/admin/profile/scheduling/DisconnectGoogleModal'),
        });
    });

    onShowModal('scheduling-paired-calendars-edit', (params) => {
        self.$modals.open({
            name: 'scheduling-paired-calendars-edit-modal',
            props: {
                id: params.id,
            },
            component: () => import('@/apps/admin/views/admin/profile/scheduling/PairedCalendarsModal'),
            onClose: () => {
                reloadLastDataTable();
            },
        });
    });

    onShowModal('scheduling-paired-calendars-delete', (params) => {
        self.$modals.open({
            name: 'scheduling-paired-calendars-delete-modal',
            props: {
                id: params.id,
            },
            component: () => import('@/apps/admin/views/admin/profile/scheduling/DeletePairedCalendarsModal'),
            onClose: () => {
                reloadLastDataTable();
            },
        });
    });

    onShowModal('help-documentation-modal', (params) => {
        self.$modals.open({
            name: 'help-documentation-modal',
            props: {
                title: params.title,
                content: params.renderedContent,
            },
            component: () => import('@/components/dialogs/common/HelpDocumentation/HelpDocumentationModal'),
        });
    });
}

export default init;
