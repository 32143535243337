import XTwigPage from '@/components/base/XTwigPage';

const CustomersList = () => import(/* webpackChunkName: "customer_list" */ '@/apps/admin/views/admin/customers/CustomersList');

export default [
    {
        path: '',
        name: 'admin.customers.list.index',
        component: CustomersList,
        meta: {
            breadcrumbs: [
                { title: ['customers', 'Customers'], url: '/admin/dashboard' },
                { title: ['customers', 'List'], url: '/admin/crm/customers/list' },
            ],
            keepAlive: false,
            title: ['customers', 'Customers list'],
            componentName: 'CustomersList',
        },
    },
    {
        delimiter: '--',
        path: 'all',
        name: 'admin.customers.list.all',
        component: XTwigPage,
        meta: {
            title: ['customers', 'All customers'],
        },
    },
    {
        delimiter: '--',
        path: 'online',
        name: 'admin.customers.list.online',
        component: XTwigPage,
        meta: {
            title: ['customers', 'Online customers'],
        },
    },
];
