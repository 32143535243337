function supports_html5_storage() {
    try {
        return 'localStorage' in window && window.localStorage !== null;
    } catch (e) {
        return false;
    }
}

/**
 * Check if var is empty. Analog PHP function empty()
 * This function does not check the object. The object will always be non-empty.
 * Use the @emptyObject function to validate the object correctly
 *
 * @param variable
 * @returns {boolean}
 */
function empty(variable) {
    return (
        variable == undefined
        || variable === ''
        || variable === 0
        || variable === '0'
        || variable === null
        || variable === false
        || (variable instanceof Array && variable.length === 0)
    );
}

function isset(object, path) {
    let findPath;
    if (typeof object !== 'object' || object === null) {
        return false;
    }

    if (typeof path === 'number') {
        path = path.toString();
    }

    let parts;
    if (typeof path === 'string' || path instanceof String) {
        parts = [path];
    } else if (Array.isArray(path)) {
        parts = path;
    } else {
        return false;
    }

    findPath = function (object, path) {
        let first = path.shift().toString();
        if (object === null || typeof object !== 'object' || typeof object[first] === 'undefined') {
            return false;
        }

        if (path.length <= 0) {
            return true;
        }

        return findPath(object[first], path);
    };

    return findPath(object, parts);
}

function getUrlParam(name) {
    let results = new RegExp(`[?&]${name}=([^&#]*)`).exec(window.location.href);
    return results[1] || 0;
}

/**
 * Check object is empty. Also works correctly with arrays.
 * @param someObject
 * @returns {boolean}
 */
function emptyObject(someObject) {
    return someObject === undefined || Object.keys(someObject).length <= 0;
}

/**
 * Sort JS object by internal attribute
 * @param object
 * @param propertyName
 * @param deep
 * @returns {{}}
 */
function sortObjectByProperty(object, propertyName, deep = false, useMap = false) {
    let positionList = {};
    for (let key in object) {
        if (!Object.prototype.hasOwnProperty.call(object, key)) {
            continue;
        }

        if (deep) {
            let objectItem = object[key];
            for (let positionKey in propertyName) {
                if (!Object.prototype.hasOwnProperty.call(propertyName, positionKey)) {
                    continue;
                }
                objectItem = objectItem[propertyName[positionKey]];
            }
            positionList[key] = objectItem;
        } else {
            positionList[key] = parseInt(object[key][propertyName]);
        }
    }

    let sortedPosition = [];
    for (let key in positionList) {
        sortedPosition.push([key, positionList[key]]);
    }

    sortedPosition.sort((a, b) => a[1] - b[1]);

    let newList = {};
    if (useMap) {
        newList = new Map();
    }
    for (let itemKey in sortedPosition) {
        if (!Object.prototype.hasOwnProperty.call(sortedPosition, itemKey)) {
            continue;
        }

        if (useMap) {
            newList.set(sortedPosition[itemKey][0], object[sortedPosition[itemKey][0]]);
        } else {
            newList[sortedPosition[itemKey][0]] = object[sortedPosition[itemKey][0]];
        }
    }

    return newList;
}

/**
 * Get value in object by path. Path example: Device.Info.IP where . divide object attributes.
 * @param object
 * @param path
 * @returns {undefined|*}
 */
function getValueByObjectPath(object, path) {
    const pathItems = path.split('.');
    let value = object;
    for (let objectPathKey in pathItems) {
        if (!Object.prototype.hasOwnProperty.call(pathItems, objectPathKey)) {
            continue;
        }

        if (value[pathItems[objectPathKey]] === undefined) {
            return undefined;
        }

        value = value[pathItems[objectPathKey]];
    }

    return value;
}

/**
 * Filter objects by object attribute value
 * @param objects
 * @param path
 * @param value
 * @returns {{object}}
 */
function filterObjectsByValueInPath(objects, path, value) {
    let result = {};

    for (let key in objects) {
        if (Object.prototype.hasOwnProperty.call(objects, key) && getValueByObjectPath(objects[key], path) === value) {
            result[key] = objects[key];
        }
    }

    return result;
}

function rand(min, max) {
    return Math.random() * (max - min) + min;
}

function getUrlParams(url) {
    if (typeof url === 'undefined') {
        url = window.location.href;
    }

    let urlObj = new URL(url);
    return new URLSearchParams(urlObj.search);
}

function getWindowWidth() {
    return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
}

function getWindowHeight() {
    return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
}

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
function debounce(func, wait, immediate) {
    let timeout;
    return function () {
        let context = this; let
            args = arguments;
        let later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        let callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}

function mergeDeep(target, source) {
    let output = { ...target };
    if (isObject(target) && isObject(source)) {
        Object.keys(source).forEach((key) => {
            if (isObject(source[key])) {
                if (!(key in target)) Object.assign(output, { [key]: source[key] });
                else output[key] = mergeDeep(target[key], source[key]);
            } else {
                Object.assign(output, { [key]: source[key] });
            }
        });
    }
    return output;
}

function processingAdditionalFields(additionalAttributes, afsForAdding) {
    let result = {};
    Object.entries(afsForAdding).forEach(([key, afMeta]) => {
        let processingValue = additionalAttributes[key];
        let isAjax = isset(afMeta, 'ajax') && afMeta.ajax;

        if (isAjax) {
            if (Array.isArray(processingValue)) {
                if (afMeta.type === 'relation_multiple') {
                    let arr = [];
                    processingValue.forEach((item) => {
                        arr.push(item.id);
                    });
                    processingValue = arr;
                } else {
                    let first = processingValue[0];
                    if (typeof first === 'object') {
                        processingValue = first.id;
                    } else {
                        processingValue = first;
                    }
                }
            } else if (typeof processingValue === 'object') {
                processingValue = processingValue.id;
            }
        }
        if (afMeta.type === 'file') {
            result[key] = processingValue;
        } else {
            result[key] = processingRawTypes(processingValue);
        }
    });
    return result;
}

function processingRawTypes(value) {
    // There are examples when the type "NaN" comes in the values when sending to the server
    // and php does not process it correctly, I made such a conversion so that everything would work as before
    return JSON.parse(JSON.stringify(value));
}

function nl2br(string, isXhtml) {
    if (typeof string === 'undefined' || string === null) {
        return '';
    }
    let breakTag = (isXhtml || typeof isXhtml === 'undefined') ? '<br />' : '<br>';
    return (`${string}`).replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, `$1${breakTag}$2`);
}

function promisifiedAjax(data) {
    return new Promise((resolve, reject) => {
        $.ajax({
            ...data,
            success(data) {
                resolve(data);
            },
            error(error) {
                reject(error);
            },
        });
    });
}

const setSelectionRange = (input, selectionStart, selectionEnd) => {
    if (input.setSelectionRange) {
        input.focus();
        input.setSelectionRange(selectionStart, selectionEnd);
    } else if (input.createTextRange) {
        let range = input.createTextRange();
        range.collapse(true);
        range.moveEnd('character', selectionEnd);
        range.moveStart('character', selectionStart);
        range.select();
    }
};

const setCaretToPosition = (input, pos) => {
    setSelectionRange(input, pos, pos);
};

const getNewInputValue = (e, isVisible) => {
    let newValue = e.target.getAttribute('data-dry-value');
    let valueArray = newValue.split('');
    const selectionLength = (e.target.selectionEnd - e.target.selectionStart);
    if (e.keyCode === 90 && (e.ctrlKey || e.metaKey)) {
        const prevValue = e.target.getAttribute('data-prev-value') || '';
        return {
            focusOn: e.target.selectionEnd + prevValue.length,
            value: prevValue,
        };
    }
    const pasteValueData = !!e.clipboardData && e.clipboardData.getData('Text');
    if (pasteValueData) {
        valueArray.splice(e.target.selectionStart, selectionLength, pasteValueData);
        newValue = valueArray.join('');
        e.target.setAttribute('data-prev-value', e.target.getAttribute('data-dry-value'));
        return {
            focusOn: e.target.selectionEnd + pasteValueData.length,
            value: newValue,
        };
    }
    if (e.type === 'cut') {
        const selection = document.getSelection();
        let selectionString = selection.toString();
        if (!selectionString) {
            selectionString = newValue.slice(e.target.selectionStart, e.target.selectionEnd);
        }
        if (isVisible) {
            e.clipboardData.setData('text/plain', selectionString);
        }
        valueArray.splice(e.target.selectionStart, selectionString.length);
        selection.deleteFromDocument();
        return {
            focusOn: e.target.selectionStart,
            value: valueArray.join(''),
        };
    }
    if (e.metaKey || e.ctrlKey) {
        return null;
    }
    let difference = 1;
    if (e.key === 'Backspace') {
        if (selectionLength === 0 && e.target.selectionStart > 0) {
            valueArray.splice(e.target.selectionStart - 1, selectionLength + 1);
        } else {
            valueArray.splice(e.target.selectionStart, selectionLength);
        }
        difference = -1;
    } else if (e.key === 'Delete' || !e.key) {
        if (selectionLength === 0) {
            valueArray.splice(e.target.selectionStart, selectionLength + 1);
        } else {
            valueArray.splice(e.target.selectionStart, selectionLength);
        }
        difference = -1;
    } else if (e.key.length > 1) {
        return null;
    } else {
        valueArray.splice(e.target.selectionStart, selectionLength, e.key);
    }
    newValue = valueArray.join('');
    e.target.setAttribute('data-prev-value', e.target.getAttribute('data-dry-value'));
    return {
        focusOn: e.target.selectionStart + difference,
        value: newValue,
    };
};

function getValueWithCurrency(value) {
    let pattern = window.currency_format_pattern;
    if (typeof value === 'string') {
        return pattern.replace('0', value.trim());
    }
    if (typeof value === 'number') {
        return pattern.replace('0', value);
    }
    return '';
}

function format(number) {
    return new RealNumber(number, {
        float: window.spl_config.numberConfig.decimal_finance.digits,
        thousandSeparator: window.spl_config.numberConfig.thousand_finance.separator,
        decimalSeparator: window.spl_config.numberConfig.decimal_finance.separator,
    })._stringFormat;
}

function taxFormat(number) {
    return new RealNumber(number, {
        float: window.spl_config.numberConfig.tax.digits,
        thousandSeparator: window.spl_config.numberConfig.thousand_finance.separator,
        decimalSeparator: window.spl_config.numberConfig.decimal_finance.separator,
    })._stringFormat;
}

function formatDate(date) {
    let format = window.moment(date, 'YYYY-MM-DD').format(date_format);
    if (format !== 'Invalid date') {
        return format;
    }
    return '';
}

function toCamelCase(str) {
    if (typeof str !== 'string') {
        return '';
    }
    return str.replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
}

function formattedResponse(response) {
    if (typeof response !== 'object') {
        return response;
    }
    return Object.keys(response).reduce((acc, key) => {
        acc[toCamelCase(key)] = response[key];
        return acc;
    }, {});
}

function sizeInBytesToStringValue(bytes, roundLength, kb_is, bits) {
    if (typeof bytes === 'undefined' || bytes === '') {
        return '';
    }
    if (typeof roundLength === 'undefined') {
        roundLength = 2;
    }
    if (typeof kb_is === 'undefined') {
        kb_is = window.spl_config.numberConfig.networking.size_of_kb;
    }
    if (typeof bits === 'undefined') {
        bits = false;
    }

    bytes = parseFloat(bytes);

    let types;
    let i;

    if (bits) {
        types = ['b', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb'];
    } else {
        types = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    }

    for (i = 0; bytes >= kb_is && i < (types.length - 1); i++) {
        bytes /= kb_is;
    }

    return `${bytes.toFixed(roundLength) / 1} ${types[i]}`;
}

export {
    supports_html5_storage,
    empty,
    isset,
    nl2br,
    getUrlParam,
    emptyObject,
    sortObjectByProperty,
    getValueByObjectPath,
    filterObjectsByValueInPath,
    rand,
    getUrlParams,
    getWindowWidth,
    getWindowHeight,
    debounce,
    mergeDeep,
    isObject,
    processingAdditionalFields,
    promisifiedAjax,
    processingRawTypes,
    getNewInputValue,
    setCaretToPosition,
    setSelectionRange,
    getValueWithCurrency,
    format,
    taxFormat,
    formatDate,
    toCamelCase,
    formattedResponse,
    sizeInBytesToStringValue,
};
