import XTwigPage from '@/components/base/XTwigPage';

const RecurringPlansList = () => import(/* webpackChunkName: "recurring_plans" */ '@/apps/admin/views/admin/tariffs/RecurringPlansList');

export default [
    {
        path: '',
        name: 'admin.tariffs.recurring',
        component: RecurringPlansList,
        meta: {
            breadcrumbs: [
                { title: ['common', 'Tariffs plans'], url: '/admin/tariffs/recurring' },
                { title: ['common', 'Recurring'], url: '/admin/tariffs/recurring' },
            ],
            title: ['tariffs', 'Tariffs'],
            keepAlive: true,
            componentName: 'RecurringPlansList',
        },
    },
    {
        delimiter: '--', // Use -- instead of / as path delimiter. Custom prop. Not from vue-router.
        path: 'add',
        name: 'admin.tariffs.recurring.add',
        component: XTwigPage,
    },
    {
        delimiter: '--',
        path: 'edit',
        name: 'admin.tariffs.recurring.edit',
        component: XTwigPage,
    },
    {
        delimiter: '--',
        path: 'change-tariff-on-services',
        name: 'admin.tariffs.recurring.change_tariff_on_services',
        component: XTwigPage,
    },
    {
        delimiter: '--',
        path: 'table',
        name: 'admin.tariffs.recurring.table',
        component: XTwigPage,
        meta: {
            title: ['tariffs', 'Tariff plan table'],
        },
    },
];
