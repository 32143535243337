<template>
    <div
        v-if="license.show"
        :class="['license-wrapper', 'row', 'alert', 'alert-' + license.label]"
    >
        <x-form
            id="admin_self_buy_form"
            ref="form"
            role="form"
            method="POST"
            target="_blank"
            :action="license.buyLicenseUrl"
        >
            <x-input-hidden
                id="admin_self_buy_form_license"
                name="license"
                :value="license.licenseCode"
            />
            <x-translate
                tag="false"
                category="config"
                :message="license.message"
            >
                <template #icon>
                    <span
                        class="btn-icon-sm"
                        style="vertical-align: middle"
                    >
                        <i class="icon-ic_fluent_warning_24_regular" />
                    </span>
                </template>
                <template #days>
                    {{ license.daysToExpiration }}
                </template>
            </x-translate>

            <x-translate
                v-if="license.isShowBuyLink"
                tag="false"
                category="config"
                message="You can {buy} a subscription or {reload} license if you have active subscription."
            >
                <template #buy>
                    <button
                        id="admin_self_buy_form_submit_button"
                        class="btn btn-success btn-sm"
                        @click.prevent="submitBuyForm"
                    >
                        {{ $t('config', 'buy') }}
                    </button>
                </template>

                <template #reload>
                    <button
                        id="admin_self_buy_form_reload_button"
                        class="btn btn-primary btn-sm"
                        @click.prevent="reloadLicense"
                    >
                        {{ $t('config', 'reload') }}
                    </button>
                </template>
            </x-translate>
        </x-form>
    </div>
</template>

<script>
import { XForm } from '@/components/common/XForm';
import { XInputHidden } from '@/components/common/inputs/XInputHidden';

export default {
    name: 'XLicense',
    components: {
        XForm,
        XInputHidden,
    },
    props: {
        license: {
            type: Object,
            default() {
                return {
                    show: false,
                    isShowBuyLink: false,
                    label: '',
                    daysToExpiration: 0,
                    buyLicenseUrl: '',
                    licenseCode: '',
                    message: '',
                };
            },
        },
    },
    methods: {
        submitBuyForm() {
            $.get('/admin/tools--check-license');
            $(this.$refs.form.$el).submit();
        },
        reloadLicense() {
            $.ajax({
                type: 'GET',
                url: '/admin/tools--update-license',
                dataType: 'json',
                success(data) {
                    if (data.result) {
                        show_success('Success! Page will be reloaded in 2 seconds!', 4);
                        setTimeout(() => {
                            window.location.reload();
                        }, 2000);
                    } else {
                        show_error('Error when trying reload license!', 4);
                    }
                },
            });
        },
    },
};
</script>

<style scoped>
    .license-wrapper {
        margin: 0;
    }
</style>
