import XTwigPage from '@/components/base/XTwigPage';

const SchedulingArchiveList = () => import(/* webpackChunkName: "scheduling_archive" */ '@/apps/admin/views/admin/scheduling/archive/SchedulingArchiveList');

export default [
    {
        path: '',
        name: 'admin.scheduling.archive',
        component: SchedulingArchiveList,
        meta: {
            keepAlive: true,
            componentName: 'SchedulingArchiveList',
        },
    },
    {
        delimiter: '--', // Use -- instead of / as path delimiter. Custom prop. Not from vue-router.
        path: 'add',
        name: 'admin.scheduling.archive.add',
        component: XTwigPage,
    },
    {
        delimiter: '--',
        path: 'view',
        name: 'admin.scheduling.archive.view',
        component: XTwigPage,
    },
];
