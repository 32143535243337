import { SET_LOCATIONS } from '../mutation-types';

const state = {
    locations: {},
};

const getters = {
    locations(state) {
        return state.locations;
    },
    locationsWithAllOption(state) {
        let allLocations = {};
        allLocations['0'] = window.xApp.$splang.t('common', 'All');

        for (let k in state.locations) {
            allLocations[k] = state.locations[k];
        }

        return allLocations;
    },
};

const actions = {
    loadLocations({ commit }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: '/admin/administration/locations--get-all-locations-list',
                success: (response) => {
                    commit(SET_LOCATIONS, response);
                    resolve();
                },
                error: () => {
                    reject();
                },
            });
        });
    },
};

const mutations = {
    [SET_LOCATIONS](state, locations) {
        state.locations = locations;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
