import {
    ON_BOARDING_CURRENT_STEP,
    ON_BOARDING_LOAD,
    ON_BOARDING_MAIN_DASHBOARD_LOAD,
    ON_BOARDING_PAGE_BILLING_FINANCE_DOCUMENTS_LOAD,
    ON_BOARDING_REFS,
    ON_BOARDING_STEPS,
    SET_APP_CHILD_REFERENCES,
    SET_ON_BOARDING_PERMISSIONS,
} from '@/apps/admin/store/mutation-types';
import { stateToGetters } from '@/apps/admin/store/helpers';
import { empty, promisifiedAjax } from '@/utils/functions';

const state = {
    modalShow: false,
    appRefs: {},
    onBoardingStepsToGo: [],
    pageBillingConfigLoad: false,
    pageFinanceDocumentsLoad: false,
    pageMainDashboardLoad: false,
    // form step
    /**
     * steps=[
     *  {
     *   selectedElements : [
     *        {
     *             element: clonedElement,
     *              offset: {
     *                 left: 0,
     *                 right: 0,
     *                 width: 0,
     *                 height: 0
     *              },
     *          }
     *      ],
     *   message:{
     *            title: '',
     *             text: '',
     *             buttonAction: fn() - example for close modal
     *   },
     *   callback: function(){
     *                  functions for next steps
     *   }
     *  }
     * ]
     *
     */
    currentStep: {},
    showDeploymentGuideButton: false,
    currentOnBoarding: '',
    rememberedStep: '',
    permissionsMap: {},
};

const actions = {
    modalShow({ commit }, modalShow) {
        commit(ON_BOARDING_LOAD, modalShow);
    },
    pageFinanceDocumentsLoadChange({ commit }, pageFinanceDocumentsLoad) {
        commit(ON_BOARDING_PAGE_BILLING_FINANCE_DOCUMENTS_LOAD, pageFinanceDocumentsLoad);
    },
    pageMainDashboardLoadChange({ commit }, pageMainDashboardLoad) {
        commit(ON_BOARDING_MAIN_DASHBOARD_LOAD, pageMainDashboardLoad);
    },
    addRefs({ commit, dispatch }, refs) {
        let clonedRefs = { ...refs };

        commit(ON_BOARDING_REFS, clonedRefs);
        commit(SET_APP_CHILD_REFERENCES, clonedRefs, { root: true });
        dispatch('addSteps');
        dispatch('checkWhatOnBoardingToLoad');
    },
    addSteps() {
        if (empty(state.onBoardingStepsToGo)) {
            state.onBoardingStepsToGo = this.getters.onBoardingSteps;
        }
    },
    checkWhatOnBoardingToLoad({ state, dispatch, commit }) {
        let count = 0;
        let timer = setInterval(() => {
            count++;
            if (count === 5) {
                clearInterval(timer);
                return;
            }

            if (state.pageFinanceDocumentsLoad && state.onBoardingStepsToGo.indexOf('billingModuleOnBoarding') > -1) {
                clearInterval(timer);
                commit('currentOnBoarding', 'billingModuleOnBoarding');
                dispatch('initOnBoarding');
            } else if (state.pageMainDashboardLoad && state.onBoardingStepsToGo.indexOf('regularUserOnBoarding') > -1) {
                clearInterval(timer);
                commit('currentOnBoarding', 'regularUserOnBoarding');
                dispatch('initOnBoarding');
            } else if (state.pageMainDashboardLoad && state.onBoardingStepsToGo.indexOf('superAdminOnBoarding') > -1) {
                clearInterval(timer);
                commit('currentOnBoarding', 'superAdminOnBoarding');
                dispatch('initOnBoarding');
            }
        }, 500);
    },
    removeRefs({ state }) {
        state.appRefs = {};
    },
    popstateHandle({ dispatch }) {
        window.onpopstate = () => {
            dispatch('closeOnBoarding');
        };
    },
    closeOnBoarding({ dispatch }) {
        dispatch('modalShow', false);
        dispatch('bodyScroll');
        dispatch('removeRefs');
    },
    initOnBoarding({ state, dispatch }) {
        if (window.innerWidth < 1024) {
            dispatch('modalShow', false);
            return;
        }
        if (state.currentOnBoarding !== '') {
            if (state.rememberedStep !== '') {
                dispatch(state.rememberedStep);
            } else {
                dispatch(state.currentOnBoarding);
            }
            dispatch('bodyScroll');

            window.addEventListener('resize', () => {
                if (state.currentOnBoarding !== '') {
                    if (window.innerWidth < 1024) {
                        dispatch('modalShow', false);
                        return;
                    }

                    if (state.rememberedStep !== '') {
                        dispatch(state.rememberedStep);
                    } else {
                        dispatch(state.currentOnBoarding);
                    }
                    dispatch('bodyScroll');
                }
            });

            dispatch('popstateHandle');
        }
    },
    billingModuleOnBoarding({ state, dispatch }) {
        let { financeDocumentsTab } = state.appRefs;
        if (typeof financeDocumentsTab !== 'undefined') {
            dispatch('billingModuleOnBoardingStepOne');
        }
    },
    billingModuleOnBoardingStepOne({ dispatch, commit }) {
        let message = {
            title: window.xApp.$splang.t('common', 'Billing tour. <span>Step 1/4</span>'),
            subtitle: window.xApp.$splang.t('common', 'New simple logic.'),
            text: window.xApp.$splang.t('common', 'We\'ve simplified the logic and use of billing in our product. Next, we\'ll show you how.'),
            buttons: [
                {
                    buttonText: window.xApp.$splang.t('common', 'Close & finish the product tour'),
                    buttonClassNames: 'btn btn-cancel',
                    buttonAction() {
                        dispatch('completeTour', 'billingModuleOnBoarding');
                        commit('rememberedStep', '');
                    },
                },
                {
                    buttonText: window.xApp.$splang.t('common', 'Next'),
                    buttonClassNames: 'btn btn-primary',
                    buttonAction() {
                        dispatch('billingModuleOnBoardingStepTwo');
                        commit('rememberedStep', 'billingModuleOnBoardingStepTwo');
                    },
                },
            ],
            offset: {
                left: 0,
                top: 324,
                width: 606,
                height: 168,
                align: 'center',
            },
        };

        let callback = function () {
        };
        let step = {
            elements: [],
            message,
            callback,
        };
        dispatch('generateStep', step);
    },
    billingModuleOnBoardingStepTwo({ dispatch, commit }) {
        let tabsCustomerBilling = document.querySelector('#customers_view_billing_tabs');
        let elements = [];
        let message = function () {
            if (typeof tabsCustomerBilling !== 'undefined') {
                elements.push(tabsCustomerBilling.querySelector('.x-tabs-bar'));
                let posElement2 = tabsCustomerBilling.querySelector('.x-tabs-bar').getBoundingClientRect();
                let leftPosMessage2 = posElement2.left - 10;
                let leftTopMessage2 = posElement2.top + posElement2.height + 10;
                return {
                    title: window.xApp.$splang.t('common', 'Billing tour. <span>Step 2/4</span>'),
                    subtitle: window.xApp.$splang.t('common', '3 Tabs.'),
                    // eslint-disable-next-line
                    text: window.xApp.$splang.t('common', 'In the first tab, we have gathered all <b>Financial documents</b> for a more unified look and feel <b>Transactions</b> have been kept as it details all billing interactions. Billing overview has also been renamed to <b>Billing config</b>.'),
                    buttons: [
                        {
                            buttonText: window.xApp.$splang.t('common', 'Close & finish the product tour'),
                            buttonClassNames: 'btn btn-cancel',
                            buttonAction() {
                                dispatch('completeTour', 'billingModuleOnBoarding');
                                commit('rememberedStep', '');
                            },
                        },
                        {
                            buttonText: window.xApp.$splang.t('common', 'Next'),
                            buttonClassNames: 'btn btn-primary',
                            buttonAction() {
                                dispatch('billingModuleOnBoardingStepThree');
                                commit('rememberedStep', 'billingModuleOnBoardingStepThree');
                            },
                        },
                    ],
                    offset: {
                        left: leftPosMessage2,
                        top: leftTopMessage2,
                        width: 532,
                        height: 208,
                    },
                };
            }
        };
        let callback = function () {
        };
        let step = {
            elements,
            message: message(),
            callback,
        };
        dispatch('generateStep', step);
    },
    billingModuleOnBoardingStepThree({ dispatch, commit }) {
        let elements = [];
        let message = function () {
            let tabsCustomerBillingOverviewBlock = document.querySelector('#tabsCustomerBillingOverviewBlock');
            if (typeof tabsCustomerBillingOverviewBlock !== 'undefined') {
                elements.push(tabsCustomerBillingOverviewBlock);
                let posElement3 = tabsCustomerBillingOverviewBlock.getBoundingClientRect();
                let leftPosMessage3 = posElement3.left - 10;
                let leftTopMessage3 = posElement3.top + posElement3.height + 20;
                return {
                    title: window.xApp.$splang.t('common', 'Billing tour. <span>Step 3/4</span>'),
                    subtitle: window.xApp.$splang.t('common', 'Customer billing overview.'),
                    text: window.xApp.$splang.t('common', 'Always visible and easily accessible so you don\'t have to switch between tabs.'),
                    buttons: [
                        {
                            buttonText: window.xApp.$splang.t('common', 'Close & finish the product tour'),
                            buttonClassNames: 'btn btn-cancel',
                            buttonAction() {
                                dispatch('completeTour', 'billingModuleOnBoarding');
                                commit('rememberedStep', '');
                            },
                        },
                        {
                            buttonText: window.xApp.$splang.t('common', 'Next'),
                            buttonClassNames: 'btn btn-primary',
                            buttonAction() {
                                dispatch('billingModuleOnBoardingStepFour');
                                commit('rememberedStep', 'billingModuleOnBoardingStepFour');
                            },
                        },
                    ],
                    offset: {
                        left: leftPosMessage3,
                        top: leftTopMessage3,
                        width: tabsCustomerBillingOverviewBlock.width,
                        height: 188,
                    },
                };
            }
        };
        let callback = function () {
        };
        let step = {
            elements,
            message: message(),
            callback,
        };
        dispatch('generateStep', step);
    },
    billingModuleOnBoardingStepFour({ state, dispatch, commit }) {
        let elements = [];
        let message = function () {
            let { billingDataTableWrapper } = state.appRefs;
            if (typeof billingDataTableWrapper !== 'undefined') {
                let table = document.querySelector('#admin_customers_view_finance_documents_list');
                let tableCells = table.querySelectorAll('th:nth-child(2), td:nth-child(2)');
                let button = document.querySelector('#addDocumentButtonHolder');
                let buttonOffset = button.getBoundingClientRect();
                elements.push(button, tableCells);
                return {
                    title: window.xApp.$splang.t('common', 'Billing tour. <span>Step 4/4</span>'),
                    subtitle: window.xApp.$splang.t('common', 'All Financial documents in one place.'),
                    // eslint-disable-next-line
                    text: window.xApp.$splang.t('common', 'We have merged all financial documents into one space - they are now easily accessible and can also be filtered to view certain types of documents. We have also introduced future items, credit notes and partial payments from version 4.0'),
                    buttons: [
                        {
                            buttonText: window.xApp.$splang.t('common', 'Close & discover the product'),
                            buttonClassNames: 'btn btn-primary',
                            buttonAction() {
                                dispatch('completeTour', 'billingModuleOnBoarding');
                                commit('rememberedStep', '');
                            },
                        },
                    ],
                    offset: {
                        left: 582,
                        top: buttonOffset.top + buttonOffset.height + 20,
                        width: 493,
                        height: 228,
                    },
                };
            }
        };

        let callback = function () {
        };
        let step = {
            elements,
            message: message(),
            callback,
        };
        dispatch('generateStep', step);
    },
    regularUserOnBoarding({ state, dispatch }) {
        let { dashboardReff } = state.appRefs;
        if (typeof dashboardReff !== 'undefined') {
            dispatch('regularUserOnBoardingStepOne');
        }
    },
    regularUserOnBoardingStepOne({ state, dispatch, commit }) {
        let { menuHolder } = state.appRefs;
        let elements = [menuHolder];
        let message = {
            title: window.xApp.$splang.t('common', 'Product tour. <span>Step 1/4</span>'),
            subtitle: window.xApp.$splang.t('common', 'Main menu.'),
            text: window.xApp.$splang.t('common', 'Here you can find all the available functions.'),
            buttons: [
                {
                    buttonText: window.xApp.$splang.t('common', 'Close & finish the product tour'),
                    buttonClassNames: 'btn btn-cancel',
                    buttonAction() {
                        dispatch('completeTour', 'regularUserOnBoarding');
                        commit('rememberedStep', '');
                    },
                },
                {
                    buttonText: window.xApp.$splang.t('common', 'Next'),
                    buttonClassNames: 'btn btn-primary',
                    buttonAction() {
                        dispatch('regularUserOnBoardingStepTwo');
                        commit('rememberedStep', 'regularUserOnBoardingStepTwo');
                    },
                },
            ],
            offset: {
                left: menuHolder.getBoundingClientRect().width + 30,
                top: 220,
                width: 372,
                height: 168,
            },
        };

        let callback = function () {
        };
        let step = {
            elements,
            message,
            callback,
        };
        dispatch('generateStep', step);
    },
    regularUserOnBoardingStepTwo({ state, dispatch, commit }) {
        let { headerNavigationHolder } = state.appRefs;
        let elements = [headerNavigationHolder];
        let left = headerNavigationHolder.getBoundingClientRect().width < 372 ? window.innerWidth - 372 : headerNavigationHolder.getBoundingClientRect().left - 10;
        let message = {
            title: window.xApp.$splang.t('common', 'Product tour. <span>Step 2/4</span>'),
            subtitle: window.xApp.$splang.t('common', 'Quick access.'),
            // eslint-disable-next-line
            text: window.xApp.$splang.t('common', 'We\'ve brought frequent and important features to a visible place where you can always ask for help,  use our powerful search tool, receive notifications or browse product documentation.'),
            buttons: [
                {
                    buttonText: window.xApp.$splang.t('common', 'Close & finish the product tour'),
                    buttonClassNames: 'btn btn-cancel',
                    buttonAction() {
                        dispatch('completeTour', 'regularUserOnBoarding');
                        commit('rememberedStep', '');
                    },
                },
                {
                    buttonText: window.xApp.$splang.t('common', 'Next'),
                    buttonClassNames: 'btn btn-primary',
                    buttonAction() {
                        dispatch('regularUserOnBoardingStepThree');
                        commit('rememberedStep', 'regularUserOnBoardingStepThree');
                    },
                },
            ],
            offset: {
                left,
                top: headerNavigationHolder.getBoundingClientRect().height + 30,
                width: 372,
                height: 168,
            },
        };

        let callback = function () {
        };
        let step = {
            elements,
            message,
            callback,
        };
        dispatch('generateStep', step);
    },
    regularUserOnBoardingStepThree({ state, dispatch, commit }) {
        let { dashboardReff } = state.appRefs;
        let elements = [dashboardReff];
        let message = {
            title: window.xApp.$splang.t('common', 'Product tour. <span>Step 3/4</span>'),
            subtitle: window.xApp.$splang.t('common', 'The dashboard.'),
            // eslint-disable-next-line
            text: window.xApp.$splang.t('common', 'Here we have collected information for you from the main modules of the product which is worth paying attention to. Some modules also have their own dashboards.'),
            buttons: [
                {
                    buttonText: window.xApp.$splang.t('common', 'Close & finish the product tour'),
                    buttonClassNames: 'btn btn-cancel',
                    buttonAction() {
                        dispatch('completeTour', 'regularUserOnBoarding');
                        commit('rememberedStep', '');
                    },
                },
                {
                    buttonText: window.xApp.$splang.t('common', 'Next'),
                    buttonClassNames: 'btn btn-primary',
                    buttonAction() {
                        dispatch('regularUserOnBoardingStepFour');
                        commit('rememberedStep', 'regularUserOnBoardingStepFour');
                    },
                },
            ],
            offset: {
                left: 0,
                top: 225,
                width: 372,
                height: 168,
            },
        };

        let callback = function () {
        };
        let step = {
            elements,
            message,
            callback,
        };
        dispatch('generateStep', step);
    },
    regularUserOnBoardingStepFour({ dispatch, commit }) {
        let elements = [];
        let text1 = window.xApp.$splang.t('common', 'You can explore the product yourself.');
        let text2 = window.xApp.$splang.t('common', 'Or');
        let text3 = window.xApp.$splang.t('common', 'watch the video');
        let text4 = window.xApp.$splang.t('common', 'that our team has recorded for you, which clarifies many aspects of Splynx product.');
        let message = {
            title: window.xApp.$splang.t('common', 'Product tour. <span>Step 4/4</span>'),
            subtitle: window.xApp.$splang.t('common', 'Where to begin?'),
            text: `${text1}</br>${text2}&nbsp;<a href="https://www.youtube.com/watch?v=UtE4oifGqZ8" target="_blank">${text3}</a>&nbsp;${text4}`,
            video: 'https://www.youtube.com/embed/UtE4oifGqZ8',
            buttons: [
                {
                    buttonText: window.xApp.$splang.t('common', 'Close & discover the product'),
                    buttonClassNames: 'btn btn-primary',
                    buttonAction() {
                        dispatch('completeTour', 'regularUserOnBoarding');
                        commit('rememberedStep', '');
                    },
                },
            ],
            offset: {
                left: 0,
                top: 60,
                width: 446,
                height: 456,
                align: 'center',
            },
        };

        let callback = function () {
        };
        let step = {
            elements,
            message,
            callback,
        };
        dispatch('generateStep', step);
    },
    superAdminOnBoarding({ state, dispatch }) {
        let { dashboardReff } = state.appRefs;
        if (typeof dashboardReff !== 'undefined') {
            dispatch('superAdminOnBoardingStepOne');
        }
    },
    superAdminOnBoardingStepOne({ state, dispatch, commit }) {
        let { menuHolder } = state.appRefs;
        let elements = [menuHolder];
        let message = {
            title: window.xApp.$splang.t('common', 'Product tour. <span>Step 1/4</span>'),
            subtitle: window.xApp.$splang.t('common', 'Main menu.'),
            text: `${window.xApp.$splang.t('common', 'Lots of items, right?')}<br>${
                window.xApp.$splang.t('common', 'You can hide the ones your business does\'t need')}&nbsp;`
                + `<a href="/admin/config/modules/main" target="_blank">${window.xApp.$splang.t('common', 'on the Config page')}</a>`,
            buttons: [
                {
                    buttonText: window.xApp.$splang.t('common', 'Close & finish the product tour'),
                    buttonClassNames: 'btn btn-cancel',
                    buttonAction() {
                        dispatch('completeTour', 'superAdminOnBoarding');
                        commit('rememberedStep', '');
                    },
                },
                {
                    buttonText: window.xApp.$splang.t('common', 'Next'),
                    buttonClassNames: 'btn btn-primary',
                    buttonAction() {
                        dispatch('superAdminOnBoardingStepTwo');
                        commit('rememberedStep', 'superAdminOnBoardingStepTwo');
                    },
                },
            ],
            offset: {
                left: menuHolder.getBoundingClientRect().width + 30,
                top: 220,
                width: 372,
                height: 168,
            },
        };

        let callback = function () {
        };
        let step = {
            elements,
            message,
            callback,
        };
        dispatch('generateStep', step);
    },
    superAdminOnBoardingStepTwo({ state, dispatch, commit }) {
        let { headerNavigationHolder } = state.appRefs;
        let elements = [headerNavigationHolder];
        let message = {
            title: window.xApp.$splang.t('common', 'Product tour. <span>Step 2/4</span>'),
            subtitle: window.xApp.$splang.t('common', 'Quick access.'),
            // eslint-disable-next-line
            text: window.xApp.$splang.t('common', 'We\'ve brought frequent and important features to a visible place where you can always ask for help, use our powerful search tool, receive notifications or browse product documentation.'),
            buttons: [
                {
                    buttonText: window.xApp.$splang.t('common', 'Close & finish the product tour'),
                    buttonClassNames: 'btn btn-cancel',
                    buttonAction() {
                        dispatch('completeTour', 'superAdminOnBoarding');
                        commit('rememberedStep', '');
                    },
                },
                {
                    buttonText: window.xApp.$splang.t('common', 'Next'),
                    buttonClassNames: 'btn btn-primary',
                    buttonAction() {
                        dispatch('superAdminOnBoardingStepThree');
                        commit('rememberedStep', 'superAdminOnBoardingStepThree');
                    },
                },
            ],
            offset: {
                left: headerNavigationHolder.getBoundingClientRect().left - 10,
                top: headerNavigationHolder.getBoundingClientRect().height + 30,
                width: 372,
                height: 168,
            },
        };

        let callback = function () {
        };
        let step = {
            elements,
            message,
            callback,
        };
        dispatch('generateStep', step);
    },
    superAdminOnBoardingStepThree({ state, dispatch, commit }) {
        let { dashboardReff } = state.appRefs;
        let elements = [dashboardReff];
        let message = {
            title: window.xApp.$splang.t('common', 'Product tour. <span>Step 3/4</span>'),
            subtitle: window.xApp.$splang.t('common', 'The dashboard.'),
            // eslint-disable-next-line
            text: window.xApp.$splang.t('common', 'Here we have collected information for you from the main modules of the product, which is worth paying attention to.<br> Some modules also have their own dashboards.'),
            buttons: [
                {
                    buttonText: window.xApp.$splang.t('common', 'Close & finish the product tour'),
                    buttonClassNames: 'btn btn-cancel',
                    buttonAction() {
                        dispatch('completeTour', 'superAdminOnBoarding');
                        commit('rememberedStep', '');
                    },
                },
                {
                    buttonText: window.xApp.$splang.t('common', 'Next'),
                    buttonClassNames: 'btn btn-primary',
                    buttonAction() {
                        dispatch('superAdminOnBoardingStepFour');
                        commit('rememberedStep', 'superAdminOnBoardingStepFour');
                    },
                },
            ],
            offset: {
                left: 0,
                top: 225,
                width: 372,
                height: 168,
            },
        };

        let callback = function () {
        };
        let step = {
            elements,
            message,
            callback,
        };
        dispatch('generateStep', step);
    },
    superAdminOnBoardingStepFour({ dispatch, commit }) {
        let elements = [];
        let text1 = window.xApp.$splang.t('common', 'Use deployment guide');
        let text2 = window.xApp.$splang.t('common', 'Or');
        let text3 = window.xApp.$splang.t('common', 'watch the video');
        let text4 = window.xApp.$splang.t('common', 'that our team has recorded for you, which clarifies many aspects of Splynx product deployment.');
        let message = {
            title: window.xApp.$splang.t('common', 'Product tour. <span>Step 4/4</span>'),
            subtitle: window.xApp.$splang.t('common', 'Where to begin?'),
            text: `<span class="styled-link" onclick="document.getElementById('buttonDeploymentGuide').click();">${text1}.</span>&nbsp;${text2}&nbsp;`
                + `<a href="https://www.youtube.com/watch?v=UtE4oifGqZ8" target="_blank">${text3}</a>&nbsp;${text4}`,
            video: 'https://www.youtube.com/embed/UtE4oifGqZ8',
            buttons: [
                {
                    buttonText: window.xApp.$splang.t('common', 'Close & discover the product'),
                    buttonClassNames: 'btn btn-primary',
                    buttonAction() {
                        dispatch('completeTour', 'superAdminOnBoarding');
                        commit('rememberedStep', '');
                    },
                },
            ],
            offset: {
                left: 0,
                top: 60,
                width: 446,
                height: 456,
                align: 'center',
            },
        };

        let callback = function () {
        };
        let step = {
            elements,
            message,
            callback,
        };
        dispatch('generateStep', step);
    },
    showDeploymentGuide() {
        if (state.onBoardingStepsToGo.indexOf('deploymentGuideEnabled') > -1) {
            state.showDeploymentGuideButton = true;
        }
    },
    hideDeploymentGuide({ state }) {
        promisifiedAjax({
            url: '/admin/profile--make-tour-as-complete',
            data: {
                tour: 'deploymentGuideEnabled',
            },
            method: 'POST',
        });
        state.showDeploymentGuideButton = false;
    },
    generateStep({ commit }, stepInfo) {
        let { elements, message, callback } = stepInfo;
        let selectedElements = [];
        let scrollTopAnchor = 0;
        let clonedElement; let left; let top; let width; let height = 0; let
            elementOffset;

        if (elements && elements.length > 0) {
            elements.forEach((element) => {
                if (Object.prototype.isPrototypeOf.call(NodeList.prototype, element)) {
                    let divWrap = document.createElement('table');
                    divWrap.classList = 'table table-striped table-without-vertical-margin';
                    element.forEach((item, index) => {
                        if (index === 0) {
                            elementOffset = item.getBoundingClientRect();
                        }
                        let row = document.createElement('tr');
                        row.appendChild(item.cloneNode(true));
                        divWrap.appendChild(row);
                    });
                    clonedElement = divWrap;
                } else {
                    elementOffset = element.getBoundingClientRect();
                    clonedElement = element.cloneNode(true);
                }

                left = parseInt(elementOffset.left) - 10;
                top = parseInt(elementOffset.top) - 8;
                width = parseInt(elementOffset.width) + 20;
                height = elementOffset.height;

                if (scrollTopAnchor === 0) {
                    scrollTopAnchor = elementOffset.top;
                } else {
                    scrollTopAnchor = scrollTopAnchor < elementOffset.top ? scrollTopAnchor : elementOffset.top;
                }

                let formedElement = {
                    element: clonedElement,
                    offset: {
                        left, top, width, height,
                    },
                };

                selectedElements.push(formedElement);
            });
        }

        let step = {
            selectedElements,
            message,
            callback,
        };
        commit(ON_BOARDING_CURRENT_STEP, step);

        commit(ON_BOARDING_LOAD, true);
    },
    enableOnBoarding({ state, commit, dispatch }, step) {
        state.onBoardingStepsToGo.push(step);
        commit('currentOnBoarding', 'billingModuleOnBoarding');
        dispatch('initOnBoarding');
    },
    completeTour({ commit, dispatch }, tourName) {
        promisifiedAjax({
            url: '/admin/profile--make-tour-as-complete',
            data: {
                tour: tourName,
            },
            method: 'POST',
        });
        dispatch('closeOnBoarding');

        let index = state.onBoardingStepsToGo.indexOf(tourName);
        if (index > -1) {
            state.onBoardingStepsToGo.splice(index, 1);
        }
        commit('currentOnBoarding', '');
    },
    bodyScroll({ state }) {
        let body = document.querySelector('body');
        if (state.modalShow) {
            body.style.overflow = 'hidden';
        } else {
            body.removeAttribute('style');
        }
    },
    loadPermissions({ commit }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: '/admin/on-boarding',
                success: (response) => {
                    commit(SET_ON_BOARDING_PERMISSIONS, response.permissions);
                    resolve(response);
                },
                error: () => {
                    reject();
                },
            });
        });
    },
};

const getters = {
    ...stateToGetters(state),
};

const mutations = {
    [ON_BOARDING_LOAD](state, modalShow) {
        state.modalShow = modalShow;
    },
    [ON_BOARDING_PAGE_BILLING_FINANCE_DOCUMENTS_LOAD](state, pageFinanceDocumentsLoad) {
        state.pageFinanceDocumentsLoad = pageFinanceDocumentsLoad;
    },
    [ON_BOARDING_MAIN_DASHBOARD_LOAD](state, pageMainDashboardLoad) {
        state.pageMainDashboardLoad = pageMainDashboardLoad;
    },
    [ON_BOARDING_REFS](state, appRefs) {
        if (typeof state.appRefs === 'undefined') {
            state.appRefs = appRefs;
        } else {
            state.appRefs = Object.assign(appRefs, state.appRefs);
        }
    },
    [ON_BOARDING_STEPS](state, steps) {
        state.steps = steps;
    },
    [ON_BOARDING_CURRENT_STEP](state, currentStep) {
        state.currentStep = currentStep;
    },
    rememberedStep(state, rememberedStep) {
        state.rememberedStep = rememberedStep;
    },
    currentOnBoarding(state, currentOnBoarding) {
        state.currentOnBoarding = currentOnBoarding;
    },
    [SET_ON_BOARDING_PERMISSIONS](state, permissions) {
        state.permissionsMap = permissions;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
