import { stateToGetters } from '@/apps/admin/store/helpers';
import sessions from '@/apps/admin/store/modules/customers/statistics/sessions';
import { SET_CUSTOMER_INFO } from '../../mutation-types';

const state = () => ({
    model: {},
    show_billing_tab: false,
    show_cpe_tab: false,
    show_services_tab: false,
    show_statistics_tab: false,
    show_documents_tab: false,
    show_cap_tab: false,
    show_communication_tab: false,
    need_show_acs_tab: false,
    show_crm_tab: false,
    addonTabs: [],
    license_message: null,
});

const getters = {
    ...stateToGetters(state()),
};

const actions = {
    loadCustomerInfo({ commit, state }, id) {
        if (!empty(id)) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: `/admin/customers/view--get-page-data?id=${id}`,
                    success: (response) => {
                        commit(SET_CUSTOMER_INFO, response);
                        resolve();
                    },
                    error: (err) => {
                        this.commit('SET_SHOW_NOT_FOUND_PAGE', true);
                        reject(err);
                    },
                });
            });
        } if (empty(state.model)) {
            show_warning(t('customers', 'Customer is not selected'), 3);
        }
    },
};

const mutations = {
    [SET_CUSTOMER_INFO](state, data) {
        for (let k in data) {
            state[k] = data[k];
        }
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
    modules: {
        sessions,
    },
};
