import {
    ACS_PROVISIONING_ADD_AVAILABLE_ATTRIBUTE_INTO_STORAGE,
    ACS_PROVISIONING_ADD_NEW_ATTRIBUTE,
    ACS_PROVISIONING_ADD_NEW_BLOCK,
    ACS_PROVISIONING_LOAD_SET_GROUP_DATA,
    ACS_PROVISIONING_MOVE_OBJECT,
    ACS_PROVISIONING_RECALCULATE_POSITIONS,
    ACS_PROVISIONING_RECALCULATE_POSITIONS_BELOW_ATTRIBUTE,
    ACS_PROVISIONING_RECALCULATE_POSITIONS_BELOW_OBJECT,
    ACS_PROVISIONING_REMOVE_ATTRIBUTE, ACS_PROVISIONING_REMOVE_AVAILABLE_ATTRIBUTE_IN_STORAGE,
    ACS_PROVISIONING_REMOVE_BLOCK, ACS_PROVISIONING_SET_ACTIVE_BLOCK_KEY,
    ACS_PROVISIONING_SET_ACTIVE_BLOCK_SELECTED_KEY,
    ACS_PROVISIONING_SET_ADD_NEW_OBJECT_STATE,
    ACS_PROVISIONING_SET_ADDING_NEW_ATTRIBUTE_STATE,
    ACS_PROVISIONING_SET_ALL_OBJECT_CHECKED_STATUS,
    ACS_PROVISIONING_SET_ATTRIBUTE_CHECKED_STATUS,
    ACS_PROVISIONING_SET_DISABLED_STATE_FOR_ATTRIBUTE,
    ACS_PROVISIONING_SET_DISABLED_STATE_FOR_OBJECT,
    ACS_PROVISIONING_SET_FILLED,
    ACS_PROVISIONING_SET_OBJECT_CHECKED_STATUS, ACS_PROVISIONING_SET_VALUE_BY_SELECTED,
    ACS_PROVISIONING_UPDATE_OBJECT_ACTION,
    ACS_PROVISIONING_UPDATE_OBJECT_KEY,
    ACS_PROVISIONING_SET_ATTRIBUTE_NEW_VALUE,
} from '@/apps/admin/store/mutation-types';
import {
    empty, emptyObject, filterObjectsByValueInPath, getUrlParam, sortObjectByProperty,
} from '@/utils/functions';
import Vue from 'vue';
import { ACS_PROVISIONING_NEW_KEY } from '@/constants';
import { UserError } from '@/exceptions';

import provision_import from './wizard/import';
import provision_fully_or_empty_devices from './wizard/fully_or_empty_device';
import one_device from './wizard/one_device';
import provision_common from './wizard/common';

const state = () => ({
    filled: null,
    blocks: null,
    blocksHashSum: null,
    objectActions: null,
    availableSplynxValuesByTypes: null,
    availableAcsTypes: null,
    availableObjects: null,
    availableObjectsAttributes: null,

    addNewBlockState: false,
    allBlocksIsChecked: false,

    activeBlockKey: 0,
    activeBlockSelectedKey: 0,
    dataTable: null,
});

const getters = {
    // General provisioning info
    filled(state) {
        return state.filled;
    },
    dataTable(state) {
        return state.dataTable;
    },
    availableAcsTypes(state) {
        return state.availableAcsTypes;
    },
    availableSplynxValuesByTypes(state) {
        return state.availableSplynxValuesByTypes;
    },
    addNewBlockState(state) {
        return state.addNewBlockState;
    },
    availableObjects(state) {
        return state.availableObjects;
    },
    allBlocksIsChecked(state) {
        return state.allBlocksIsChecked;
    },
    activeBlockKey(state) {
        return state.activeBlockKey;
    },
    activeBlockSelectedKey(state) {
        if (state.activeBlockKey !== '----new----') {
            return 0;
        }
        return state.activeBlockSelectedKey;
    },
    availableObjectsAttributes(state) {
        return state.availableObjectsAttributes;
    },

    // Mass actions info
    allCheckedAttributeCount(state) {
        let count = 0;
        for (let blockKey in state.blocks) {
            if (!Object.prototype.hasOwnProperty.call(state.blocks, blockKey)) {
                continue;
            }

            if (state.blocks[blockKey].configs.action === 'delete') {
                if (state.blocks[blockKey].configs.checked) {
                    count += 1;
                }
                continue;
            }

            const checkedAttributes = filterObjectsByValueInPath(state.blocks[blockKey].attributes, 'checked', true);
            count += Object.keys(checkedAttributes).length;
        }

        return count;
    },
    enabledCheckedAttributeCount(state) {
        return calculateCheckedEnabledAttributes(state, true);
    },
    disabledCheckedAttributeCount(state) {
        return calculateCheckedEnabledAttributes(state, false);
    },

    // Block getters
    blocks(state) {
        return state.blocks;
    },
    blocksCount(state) {
        return Object.keys(state.blocks).length;
    },
    sortedBlocks(state) {
        return sortObjectByProperty(state.blocks, ['configs', 'position'], true);
    },
    blockExist(state) {
        return (key) => {
            Object.prototype.hasOwnProperty.call(state.blocks, key);
        };
    },
    objectActions(state) {
        return state.objectActions;
    },
    addNewStateOnBlock(state) {
        for (let key in state.blocks) {
            if (!Object.prototype.hasOwnProperty.call(state.blocks, key)) {
                continue;
            }

            if (typeof state.blocks[key].attributes[ACS_PROVISIONING_NEW_KEY] !== 'undefined') {
                return true;
            }
        }

        return false;
    },
    addNewState(state) {
        return typeof state.blocks[ACS_PROVISIONING_NEW_KEY] !== 'undefined';
    },

    // Attributes getters
    configForPreview(state) {
        let objectsForPreview = JSON.parse(JSON.stringify(state.blocks));
        for (let blockKey in objectsForPreview) {
            if (!Object.prototype.hasOwnProperty.call(objectsForPreview, blockKey)) {
                continue;
            }

            if (objectsForPreview[blockKey].configs.enabled !== '1' && objectsForPreview[blockKey].configs.enabled !== true) {
                delete objectsForPreview[blockKey];
                continue;
            }

            for (let attributeKey in objectsForPreview[blockKey].attributes) {
                if (!Object.prototype.hasOwnProperty.call(objectsForPreview[blockKey].attributes, attributeKey)) {
                    continue;
                }

                if (objectsForPreview[blockKey].attributes[attributeKey].enabled !== '1' && objectsForPreview[blockKey].attributes[attributeKey].enabled !== true) {
                    delete objectsForPreview[blockKey].attributes[attributeKey];
                }
            }
        }

        return objectsForPreview;
    },
};

const actions = {
    // Server data loading
    loadProvisionInfo({ commit }, groupId) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/admin/config/networking/acs--load-group-data?id=${groupId}`,
                success: (response) => {
                    commit(ACS_PROVISIONING_LOAD_SET_GROUP_DATA, response);
                    resolve();
                },
                error: () => {
                    reject();
                },
            });
        });
    },

    // Mass actions
    baseMassActions({ state, dispatch }, data) {
        if (data.skipAttributesActionIfObjectChecked === undefined) {
            data.skipAttributesActionIfObjectChecked = false;
        }

        for (let objectKey in state.blocks) {
            if (!Object.prototype.hasOwnProperty.call(state.blocks, objectKey)) {
                continue;
            }

            if (state.blocks[objectKey].configs.checked) {
                data.objectAction(objectKey);
            }

            if (state.blocks[objectKey] === undefined || (data.skipAttributesActionIfObjectChecked && state.blocks[objectKey].configs.checked)) {
                continue;
            }

            for (let attributeKey in state.blocks[objectKey].attributes) {
                if (!Object.prototype.hasOwnProperty.call(state.blocks[objectKey].attributes, attributeKey)) {
                    continue;
                }

                if (state.blocks[objectKey].attributes[attributeKey].checked) {
                    data.attributeAction(objectKey, attributeKey);
                }
            }
        }

        dispatch('makeAllObjectChecked', false);
    },
    massDeleteAttributes({ dispatch, commit, getters }) {
        if (getters.allCheckedAttributeCount <= 0) {
            return;
        }

        const objectAction = (objectKey) => {
            commit(ACS_PROVISIONING_REMOVE_BLOCK, objectKey);
        };
        const attributeAction = (objectKey, attributeKey) => {
            commit(ACS_PROVISIONING_REMOVE_ATTRIBUTE, {
                blockKey: objectKey,
                key: attributeKey,
            });
        };
        dispatch('baseMassActions', {
            objectAction,
            attributeAction,
            skipAttributesActionIfObjectChecked: true,
        });

        if (getters.allCheckedAttributeCount === 0) {
            commit(ACS_PROVISIONING_SET_ALL_OBJECT_CHECKED_STATUS, false);
            dispatch('addNewBlockBelow', 1);
        }
    },
    baseMassEnabledDisable({ dispatch, commit }, enabledState) {
        const objectAction = (objectKey) => {
            commit(ACS_PROVISIONING_SET_DISABLED_STATE_FOR_OBJECT, {
                blockKey: objectKey,
                objectState: enabledState,
            });
        };
        const attributeAction = (objectKey, attributeKey) => {
            commit(ACS_PROVISIONING_SET_DISABLED_STATE_FOR_ATTRIBUTE, {
                blockKey: objectKey,
                key: attributeKey,
                attributeState: enabledState,
            });
        };

        dispatch('baseMassActions', {
            objectAction,
            attributeAction,
        });
    },
    massEnableAttributes({ dispatch, getters }) {
        if (getters.disabledCheckedAttributeCount > 0) {
            dispatch('baseMassEnabledDisable', true);
        }
    },
    massDisableAttributes({ dispatch, getters }) {
        if (getters.enabledCheckedAttributeCount > 0) {
            dispatch('baseMassEnabledDisable', false);
        }
    },

    // Mass actions checkbox processing
    makeBlockChecked({ state, commit }, data) {
        const { blockState } = data;
        const { blockKey } = data;

        for (let attributeKey in state.blocks[blockKey].attributes) {
            if (!Object.prototype.hasOwnProperty.call(state.blocks[blockKey].attributes, attributeKey)) {
                continue;
            }

            commit(ACS_PROVISIONING_SET_ATTRIBUTE_CHECKED_STATUS, {
                blockKey,
                attributeKey,
                checked: blockState,
            });
        }

        commit(ACS_PROVISIONING_SET_OBJECT_CHECKED_STATUS, {
            blockKey,
            checked: blockState,
        });

        let allObjectsChecked = false;
        if (Object.keys(state.blocks).length === countCheckedItemsCount(state.blocks, 'configs.checked')) {
            allObjectsChecked = true;
        }

        if (state.allBlocksIsChecked !== allObjectsChecked) {
            commit(ACS_PROVISIONING_SET_ALL_OBJECT_CHECKED_STATUS, allObjectsChecked);
        }
    },
    makeAttributeChecked({ state, commit }, data) {
        commit(ACS_PROVISIONING_SET_ATTRIBUTE_CHECKED_STATUS, data);

        let objectChecked = false;
        if (Object.keys(state.blocks[data.blockKey].attributes).length === countCheckedItemsCount(state.blocks[data.blockKey].attributes)) {
            objectChecked = true;
        }

        if (state.blocks[data.blockKey].configs.checked !== objectChecked) {
            commit(ACS_PROVISIONING_SET_OBJECT_CHECKED_STATUS, {
                blockKey: data.blockKey,
                checked: objectChecked,
            });

            let allObjectsChecked = false;
            if (Object.keys(state.blocks).length === countCheckedItemsCount(state.blocks, 'configs.checked')) {
                allObjectsChecked = true;
            }

            if (state.allBlocksIsChecked !== allObjectsChecked) {
                commit(ACS_PROVISIONING_SET_ALL_OBJECT_CHECKED_STATUS, allObjectsChecked);
            }
        }
    },
    makeAllObjectChecked({ state, dispatch }, checkedState) {
        for (let blockKey in state.blocks) {
            if (!Object.prototype.hasOwnProperty.call(state.blocks, blockKey)) {
                continue;
            }
            dispatch('makeBlockChecked', {
                blockKey,
                blockState: checkedState,
            });
        }
    },

    // Block actions
    updateObjectAction({ state, commit }, data) {
        return new Promise((resolve, reject) => {
            let blockConfig = state.blocks[data.blockKey].configs;
            let newKey = blockConfig.key.replaceAll(`~${blockConfig.action}-`, `~${data.newAction}-`);

            if (blockConfig.key === ACS_PROVISIONING_NEW_KEY) {
                commit(ACS_PROVISIONING_UPDATE_OBJECT_ACTION, {
                    action: data.newAction,
                    key: ACS_PROVISIONING_NEW_KEY,
                });
                resolve();
            }

            if (newKey === blockConfig.key) {
                reject();
                return;
            }

            if (Object.prototype.hasOwnProperty.call(state.blocks, newKey)) {
                newKey = generateObjectKey(blockConfig.selectedObject, data.newAction, state.blocks, true);
                show_error(window.xApp.$splang.t('config', 'Object with this action already exist'), 4);
            }

            commit(ACS_PROVISIONING_UPDATE_OBJECT_KEY, {
                newKey,
                key: data.blockKey,
            });
            commit(ACS_PROVISIONING_UPDATE_OBJECT_ACTION, {
                action: data.newAction,
                key: newKey,
            });

            let blockAttributes = state.blocks[newKey].attributes;
            if (emptyObject(blockAttributes) && data.newAction !== 'delete') {
                commit(ACS_PROVISIONING_ADD_NEW_ATTRIBUTE, {
                    blockKey: newKey,
                    key: ACS_PROVISIONING_NEW_KEY,
                    attribute: {
                        checked: false,
                        enabled: true,
                        position: data.position,
                        key: 0,
                        type: 'string',
                        selected: 'value',
                        value: '',
                    },
                });
            }

            resolve();
        });
    },
    setObjectActionValue({ state, commit }, data) {
        return new Promise((resolve, reject) => {
            if (!state.blocks[data.blockKey]) {
                reject();
                return;
            }
            commit(ACS_PROVISIONING_SET_ATTRIBUTE_NEW_VALUE, {
                blockKey: data.blockKey,
                key: data.newAction.name,
                attribute: data.newAction,
            });
            resolve();
        });
    },
    addNewBlockBelow({ commit, dispatch }, position) {
        let newBlockPosition = position + 1;
        commit(ACS_PROVISIONING_RECALCULATE_POSITIONS_BELOW_OBJECT, newBlockPosition);
        commit(ACS_PROVISIONING_ADD_NEW_BLOCK, {
            blockKey: ACS_PROVISIONING_NEW_KEY,
            newBlock: {
                configs: {
                    enabled: true,
                    position: newBlockPosition,
                    key: ACS_PROVISIONING_NEW_KEY,
                    selectedObject: 0,
                    action: 'create',
                },
                attributes: {},
            },
        });

        dispatch('addNewAttribute', {
            blockKey: ACS_PROVISIONING_NEW_KEY,
            position: 0,
        });

        commit(ACS_PROVISIONING_SET_ADD_NEW_OBJECT_STATE, true);
        dispatch('setActiveBlockKey', ACS_PROVISIONING_NEW_KEY);
    },
    removeWholeObject({ commit, dispatch, getters }, key) {
        if (key === ACS_PROVISIONING_NEW_KEY) {
            commit(ACS_PROVISIONING_SET_ADD_NEW_OBJECT_STATE, false);
        }

        commit(ACS_PROVISIONING_REMOVE_BLOCK, key);
        dispatch('setActiveBlockKey', 0);

        if (getters.blocksCount === 0) {
            dispatch('addNewBlockBelow', 0);
        }
    },
    saveNewBlock({ state, commit, dispatch }) {
        const newObject = state.blocks[ACS_PROVISIONING_NEW_KEY];
        let objectKey = null;

        try {
            objectKey = generateObjectKey(newObject.configs.selectedObject, newObject.configs.action, state.blocks);
        } catch (exception) {
            if (exception instanceof UserError) {
                show_error(exception.message, 4);
                return;
            }

            throw exception;
        }

        if (typeof newObject.attributes[ACS_PROVISIONING_NEW_KEY] !== 'undefined' && empty(newObject.attributes[ACS_PROVISIONING_NEW_KEY].key)) {
            show_error(window.xApp.$splang.t('config', 'Object has no configured attribute. Please configure attribute or remove him.'), 4);
            return;
        }

        let availableACSAttributes = {};
        if (typeof state.availableObjectsAttributes[newObject.configs.selectedObject] !== 'undefined') {
            availableACSAttributes = state.availableObjectsAttributes[newObject.configs.selectedObject];
        }

        commit(ACS_PROVISIONING_ADD_NEW_BLOCK, {
            blockKey: objectKey,
            newBlock: Vue.observable({
                configs: {
                    position: newObject.configs.position,
                    objectName: newObject.configs.selectedObject,
                    checked: false,
                    enabled: true,
                    checkedList: [],
                    action: newObject.configs.action,
                    key: objectKey,
                    availableACSAttributes,
                },
                attributes: newObject.attributes,
            }),
        });

        commit(ACS_PROVISIONING_REMOVE_BLOCK, ACS_PROVISIONING_NEW_KEY);

        if (typeof state.blocks[objectKey].attributes[ACS_PROVISIONING_NEW_KEY] !== 'undefined' && !empty(state.blocks[objectKey].attributes[ACS_PROVISIONING_NEW_KEY].key)) {
            dispatch('saveNewAttribute', {
                blockKey: objectKey,
            });
        }

        commit(ACS_PROVISIONING_SET_ADD_NEW_OBJECT_STATE, false);
    },

    // Moving blocks and attributes
    moveAttribute({ state }, data) {
        let { position } = data;
        let { direction } = data;
        let { blockKey } = data;

        const listLength = Object.keys(state.blocks[blockKey].attributes).length;

        for (let itemKey in state.blocks[blockKey].attributes) {
            if (!Object.prototype.hasOwnProperty.call(state.blocks[blockKey].attributes, itemKey)) {
                continue;
            }

            if (direction === 'up') {
                if (position - 1 === state.blocks[blockKey].attributes[itemKey].position) {
                    let item = state.blocks[blockKey].attributes[itemKey];
                    item.position += 1;
                    Vue.set(state.blocks[blockKey].attributes, itemKey, Vue.observable(item));
                } else if (position === state.blocks[blockKey].attributes[itemKey].position) {
                    let item = state.blocks[blockKey].attributes[itemKey];
                    item.position = item.position > 1 ? item.position - 1 : 1;
                    Vue.set(state.blocks[blockKey].attributes, itemKey, Vue.observable(item));
                }
            } else {
                if (listLength < position + 1) {
                    continue;
                }

                if (position === state.blocks[blockKey].attributes[itemKey].position) {
                    let item = state.blocks[blockKey].attributes[itemKey];
                    item.position += 1;
                    Vue.set(state.blocks[blockKey].attributes, itemKey, Vue.observable(item));
                } else if (position + 1 === state.blocks[blockKey].attributes[itemKey].position) {
                    let item = state.blocks[blockKey].attributes[itemKey];
                    item.position = item.position > 1 ? item.position - 1 : 1;
                    Vue.set(state.blocks[blockKey].attributes, itemKey, Vue.observable(item));
                }
            }
        }
    },

    // Attributes actions
    removeAttribute({ state, commit, dispatch }, data) {
    // process cancelling adding new attribute
        if (data.key === ACS_PROVISIONING_NEW_KEY) {
            if (Object.keys(state.blocks[data.blockKey].attributes).length === 1 && typeof state.blocks[data.blockKey].attributes[ACS_PROVISIONING_NEW_KEY] !== 'undefined') {
                return;
            }

            if (typeof data.removeNewItemFromVirtualScrollerPool === 'function') {
                data.removeNewItemFromVirtualScrollerPool.call();
            }

            commit(ACS_PROVISIONING_SET_ADDING_NEW_ATTRIBUTE_STATE, {
                blockKey: data.blockKey,
                state: false,
            });
        }

        dispatch('addAttributeInToAvailableAttributesStorage', {
            blockKey: data.blockKey,
            attribute: data.key,
        });

        commit(ACS_PROVISIONING_REMOVE_ATTRIBUTE, data);
        commit(ACS_PROVISIONING_RECALCULATE_POSITIONS, data);

        if (Object.keys(state.blocks[data.blockKey].attributes).length <= 0) {
            dispatch('addNewAttribute', {
                position: 0,
                blockKey: data.blockKey,
            });
        }
    },
    addNewAttribute({ commit }, data) {
        data.position += 1;
        commit(ACS_PROVISIONING_RECALCULATE_POSITIONS_BELOW_ATTRIBUTE, data);

        data.key = ACS_PROVISIONING_NEW_KEY;
        data.attribute = {
            checked: false,
            enabled: true,
            position: data.position,
            key: 0,
            type: 'string',
            selected: 'value',
            value: '',
        };
        commit(ACS_PROVISIONING_ADD_NEW_ATTRIBUTE, data);
        commit(ACS_PROVISIONING_SET_ADDING_NEW_ATTRIBUTE_STATE, {
            blockKey: data.blockKey,
            state: true,
        });
    },
    saveNewAttribute({ state, commit, dispatch }, data) {
        let object = state.blocks[data.blockKey].attributes[ACS_PROVISIONING_NEW_KEY];
        if (typeof state.blocks[data.blockKey].attributes[object.key] !== 'undefined') {
            show_error(t('config', 'Attribute already exist'), 4);
            return;
        }

        if (typeof data.removeNewItemFromVirtualScrollerPool === 'function') {
            data.removeNewItemFromVirtualScrollerPool.call();
        }

        commit(ACS_PROVISIONING_ADD_NEW_ATTRIBUTE, {
            blockKey: data.blockKey,
            key: object.key,
            attribute: object,
        });
        commit(ACS_PROVISIONING_REMOVE_ATTRIBUTE, {
            blockKey: data.blockKey,
            key: ACS_PROVISIONING_NEW_KEY,
        });
        commit(ACS_PROVISIONING_SET_ADDING_NEW_ATTRIBUTE_STATE, {
            blockKey: data.blockKey,
            state: false,
        });

        dispatch('removeAttributeFromAvailableAttributesStorageIfExist', {
            blockKey: data.blockKey,
            attribute: object.key,
        });
    },
    setAttributeValueBySelected({ commit }, data) {
        let { value } = data;
        if (data.selected === 'value') {
            value = '';

            commit(ACS_PROVISIONING_SET_VALUE_BY_SELECTED, {
                blockKey: data.blockKey,
                key: data.key,
                value,
            });
            return;
        }

        if (data.selected !== 'template' && data.selected !== 'ignore_statement') {
            value = `${'{'.repeat(2)} ${data.selected} ${'}'.repeat(2)}`;
        }

        if (data.selected === 'ignore_statement') {
            value = '{% if customer.login is not empty %}value{% else %}---ignore---{% endif %}';
        }

        commit(ACS_PROVISIONING_SET_VALUE_BY_SELECTED, {
            blockKey: data.blockKey,
            key: data.key,
            value,
        });
    },
    checkIfAttributeIsDefault({ state, commit }, data) {
        return new Promise((resolve, reject) => {
            if (typeof state.blocks[data.blockKey] === 'undefined' || typeof state.blocks[data.blockKey].attributes[ACS_PROVISIONING_NEW_KEY] === 'undefined') {
                resolve();
            }

            let attribute = state.blocks[data.blockKey].attributes[ACS_PROVISIONING_NEW_KEY];
            if (data.value === 0) {
                attribute.type = null;
                commit(ACS_PROVISIONING_ADD_NEW_ATTRIBUTE, {
                    key: ACS_PROVISIONING_NEW_KEY,
                    blockKey: data.blockKey,
                    attribute,
                });
                resolve();
            }

            let selectedFullKeyPath = `${state.blocks[data.blockKey].configs.objectName}.${data.value}`;
            $.ajax({
                url: `/admin/config/networking/acs--load-params-for-provision-flow?id=${getUrlParam('id')}&paramName=${selectedFullKeyPath}`,
                success: (response) => {
                    attribute.newItemIsDefault = response.isDefault;
                    if (response.deniedAttributeName) {
                        reject(window.xApp.$splang.t('config', 'This readonly attribute'));
                    }

                    if (!response.isDefault) {
                        attribute.type = 'string';
                    } else {
                        attribute.type = response.type;
                        attribute.selected = 'value';
                        attribute.value = response.value;
                    }

                    commit(ACS_PROVISIONING_ADD_NEW_ATTRIBUTE, {
                        key: ACS_PROVISIONING_NEW_KEY,
                        blockKey: data.blockKey,
                        attribute,
                    });
                    resolve();
                },
                error(error) {
                    reject(error);
                },
            });
        });
    },
    removeAttributeFromAvailableAttributesStorageIfExist({ state, commit }, data) {
        if (typeof state.blocks[data.blockKey].configs.availableACSAttributes === 'undefined'
        || typeof state.blocks[data.blockKey].configs.availableACSAttributes[data.attribute] === 'undefined'
        ) {
            return;
        }

        commit(ACS_PROVISIONING_REMOVE_AVAILABLE_ATTRIBUTE_IN_STORAGE, data);
    },
    addAttributeInToAvailableAttributesStorage({ state, commit }, data) {
        if (data.attribute === ACS_PROVISIONING_NEW_KEY) {
            return;
        }

        if (typeof state.blocks[data.blockKey].configs.usedAvailableACSAttributes !== 'undefined'
        && typeof state.blocks[data.blockKey].configs.usedAvailableACSAttributes[data.attribute] !== 'undefined'
        ) {
            commit(ACS_PROVISIONING_ADD_AVAILABLE_ATTRIBUTE_INTO_STORAGE, data);
        }

        if (typeof state.blocks[data.blockKey].attributes[data.attribute] !== 'undefined') {
            let attribute = state.blocks[data.blockKey].attributes[data.attribute];
            if (typeof attribute.isDefault !== 'undefined' && attribute.isDefault) {
                commit(ACS_PROVISIONING_ADD_AVAILABLE_ATTRIBUTE_INTO_STORAGE, data);
            }
        }
    },

    setManuallyInsertConfigs({ commit }, filledState) {
        commit(ACS_PROVISIONING_SET_FILLED, filledState);
    },
    setActiveBlockKey({ commit }, data) {
        commit(ACS_PROVISIONING_SET_ACTIVE_BLOCK_KEY, data);
    },
    setActiveBlockSelectedKey({ commit }, data) {
        commit(ACS_PROVISIONING_SET_ACTIVE_BLOCK_SELECTED_KEY, data);
    },
};

const mutations = {
    [ACS_PROVISIONING_LOAD_SET_GROUP_DATA](state, data) {
        state.filled = data.filled;
        state.blocksHashSum = hashCode(JSON.stringify(data.blocks));
        state.dataTable = data.dataTable;
        state.blocks = data.blocks;
        state.objectActions = data.objectActions;
        state.availableSplynxValuesByTypes = data.availableSplynxValuesByTypes;
        state.availableAcsTypes = data.availableAcsTypes;
        state.availableObjects = data.availableObjects;
        state.availableObjectsAttributes = data.availableObjectsAttributes;
    },
    [ACS_PROVISIONING_UPDATE_OBJECT_KEY](state, data) {
        if (!Object.prototype.hasOwnProperty.call(state.blocks, data.key)) {
            return;
        }

        let block = state.blocks[data.key];
        block.configs.key = data.newKey;

        Vue.set(state.blocks, data.newKey, block);
        Vue.delete(state.blocks, data.key);
    },
    [ACS_PROVISIONING_UPDATE_OBJECT_ACTION](state, data) {
        if (!Object.prototype.hasOwnProperty.call(state.blocks, data.key)) {
            return;
        }
        let blocks = state.blocks[data.key];
        blocks.configs.action = data.action;
        Vue.set(state.blocks, data.key, blocks);
    },
    [ACS_PROVISIONING_ADD_NEW_ATTRIBUTE](state, data) {
        Vue.set(state.blocks[data.blockKey].attributes, data.key, data.attribute);
    },
    [ACS_PROVISIONING_RECALCULATE_POSITIONS_BELOW_ATTRIBUTE](state, data) {
        for (let itemKey in state.blocks[data.blockKey].attributes) {
            if (!Object.prototype.hasOwnProperty.call(state.blocks[data.blockKey].attributes, itemKey)) {
                continue;
            }

            if (data.position <= state.blocks[data.blockKey].attributes[itemKey].position) {
                let item = state.blocks[data.blockKey].attributes[itemKey];
                item.position += 1;
                Vue.set(state.blocks[data.blockKey].attributes, itemKey, Vue.observable(item));
            }
        }
    },
    [ACS_PROVISIONING_REMOVE_ATTRIBUTE](state, data) {
        Vue.delete(state.blocks[data.blockKey].attributes, data.key);
    },
    [ACS_PROVISIONING_RECALCULATE_POSITIONS](state, data) {
        let counter = 1;

        let sortedAttributes = sortObjectByProperty(state.blocks[data.blockKey].attributes, 'position');
        for (let itemKey in sortedAttributes) {
            if (!Object.prototype.hasOwnProperty.call(state.blocks[data.blockKey].attributes, itemKey)) {
                continue;
            }

            let item = state.blocks[data.blockKey].attributes[itemKey];
            item.position = counter++;
            Vue.set(state.blocks[data.blockKey].attributes, itemKey, Vue.observable(item));
        }
    },
    [ACS_PROVISIONING_MOVE_OBJECT](state, data) {
        let { position } = data;
        let { direction } = data;

        const listLength = Object.keys(state.blocks).length;

        for (let itemKey in state.blocks) {
            if (!Object.prototype.hasOwnProperty.call(state.blocks, itemKey)) {
                continue;
            }

            if (direction === 'up') {
                if (position - 1 === state.blocks[itemKey].configs.position) {
                    let item = state.blocks[itemKey];
                    item.configs.position += 1;
                    Vue.set(state.blocks, itemKey, Vue.observable(item));
                } else if (position === state.blocks[itemKey].configs.position) {
                    let item = state.blocks[itemKey];
                    item.configs.position = item.configs.position > 1 ? item.configs.position - 1 : 1;
                    Vue.set(state.blocks, itemKey, Vue.observable(item));
                }
            } else {
                if (listLength < position + 1) {
                    continue;
                }

                if (position === state.blocks[itemKey].configs.position) {
                    let item = state.blocks[itemKey];
                    item.configs.position += 1;
                    Vue.set(state.blocks, itemKey, Vue.observable(item));
                } else if (position + 1 === state.blocks[itemKey].configs.position) {
                    let item = state.blocks[itemKey];
                    item.configs.position = item.configs.position > 1 ? item.configs.position - 1 : 1;
                    Vue.set(state.blocks, itemKey, Vue.observable(item));
                }
            }
        }
    },
    [ACS_PROVISIONING_SET_ADDING_NEW_ATTRIBUTE_STATE](state, data) {
        Vue.set(state.blocks[data.blockKey], 'addNewAttributeState', data.state);
    },
    [ACS_PROVISIONING_RECALCULATE_POSITIONS_BELOW_OBJECT](state, position) {
        for (let itemKey in state.blocks) {
            if (!Object.prototype.hasOwnProperty.call(state.blocks, itemKey)) {
                continue;
            }

            if (position <= state.blocks[itemKey].configs.position) {
                let item = state.blocks[itemKey];
                item.configs.position += 1;
                Vue.set(state.blocks, itemKey, Vue.observable(item));
            }
        }
    },
    [ACS_PROVISIONING_ADD_NEW_BLOCK](state, data) {
        Vue.set(state.blocks, data.blockKey, Vue.observable(data.newBlock));
    },
    [ACS_PROVISIONING_REMOVE_BLOCK](state, objectKey) {
        Vue.delete(state.blocks, objectKey);
    },
    [ACS_PROVISIONING_SET_ADD_NEW_OBJECT_STATE](state, stateValue) {
        state.addNewBlockState = stateValue;
    },
    [ACS_PROVISIONING_SET_ATTRIBUTE_CHECKED_STATUS](state, data) {
        let key = data.attributeKey;
        if (empty(key)) {
            key = ACS_PROVISIONING_NEW_KEY;
        }
        let attribute = state.blocks[data.blockKey].attributes[key];
        attribute.checked = data.checked;
        Vue.set(state.blocks[data.blockKey].attributes, key, attribute);
    },
    [ACS_PROVISIONING_SET_ATTRIBUTE_NEW_VALUE](state, data) {
        state.blocks[data.blockKey].attributes[data.key].value = data.attribute.value;
    },
    [ACS_PROVISIONING_SET_OBJECT_CHECKED_STATUS](state, data) {
        Vue.set(state.blocks[data.blockKey].configs, 'checked', data.checked);
    },
    [ACS_PROVISIONING_SET_ALL_OBJECT_CHECKED_STATUS](state, checkedState) {
        state.allBlocksIsChecked = checkedState;
    },
    [ACS_PROVISIONING_SET_DISABLED_STATE_FOR_OBJECT](state, data) {
        Vue.set(state.blocks[data.blockKey].configs, 'enabled', data.objectState);
    },
    [ACS_PROVISIONING_SET_DISABLED_STATE_FOR_ATTRIBUTE](state, data) {
        Vue.set(state.blocks[data.blockKey].attributes[data.key], 'enabled', data.attributeState);
    },
    [ACS_PROVISIONING_SET_FILLED](state, filledState) {
        state.filled = filledState;
    },
    [ACS_PROVISIONING_SET_VALUE_BY_SELECTED](state, data) {
        Vue.set(state.blocks[data.blockKey].attributes[data.key], 'value', data.value);
    },
    [ACS_PROVISIONING_ADD_AVAILABLE_ATTRIBUTE_INTO_STORAGE](state, data) {
        if (typeof state.blocks[data.blockKey].configs.usedAvailableACSAttributes !== 'undefined'
        && typeof state.blocks[data.blockKey].configs.usedAvailableACSAttributes[data.attribute] !== 'undefined'
        ) {
            Vue.delete(state.blocks[data.blockKey].configs.usedAvailableACSAttributes, data.attribute);
        }

        let availableACSAttributes = {};
        if (typeof state.blocks[data.blockKey].configs.availableACSAttributes !== 'undefined') {
            availableACSAttributes = state.blocks[data.blockKey].configs.availableACSAttributes;
        }

        availableACSAttributes[data.attribute] = data.attribute;
        Vue.set(state.blocks[data.blockKey].configs, 'availableACSAttributes', availableACSAttributes);
    },
    [ACS_PROVISIONING_REMOVE_AVAILABLE_ATTRIBUTE_IN_STORAGE](state, data) {
        Vue.delete(state.blocks[data.blockKey].configs.availableACSAttributes, data.attribute);
        let usedAvailableACSAttributes = {};
        if (typeof state.blocks[data.blockKey].configs.usedAvailableACSAttributes !== 'undefined') {
            usedAvailableACSAttributes = state.blocks[data.blockKey].configs.usedAvailableACSAttributes;
        }

        usedAvailableACSAttributes[data.attribute] = data.attribute;
        Vue.set(state.blocks[data.blockKey].configs, 'usedAvailableACSAttributes', usedAvailableACSAttributes);
    },
    [ACS_PROVISIONING_SET_ACTIVE_BLOCK_KEY](state, data) {
        state.activeBlockKey = data;
    },
    [ACS_PROVISIONING_SET_ACTIVE_BLOCK_SELECTED_KEY](state, data) {
        state.activeBlockSelectedKey = data;
    },
};

function generateObjectKey(objectName, action, blocks, ignoreCheking) {
    function findObjectInfo(objectsKeys, keyWithAction) {
        let keysCount = 0;
        let maxIndex = 0;
        for (let key in objectsKeys) {
            if (!Object.prototype.hasOwnProperty.call(objectsKeys, key)) {
                continue;
            }

            let replacesKey = objectsKeys[key].replaceAll(keyWithAction, '');
            if (replacesKey.length < objectsKeys[key].length) {
                keysCount++;
                let index = parseInt(replacesKey.replaceAll('-', ''));
                if (maxIndex < index) {
                    maxIndex = index;
                }
            }
        }

        return {
            keysCount,
            maxIndex,
        };
    }

    let generatedKey = `${objectName}~${action}`;
    let objectAndActionInfo = findObjectInfo(Object.keys(blocks), generatedKey);
    if ((ignoreCheking === undefined || ignoreCheking === false) && (action === 'update' || action === 'delete') && objectAndActionInfo.keysCount > 0) {
        throw new UserError(window.xApp.$splang.t('config', 'Object with this action already exist'));
    }
    return `${generatedKey}-${objectAndActionInfo.maxIndex + 1}`;
}

function countCheckedItemsCount(objects, path) {
    if (path === undefined) {
        path = 'checked';
    }
    return Object.keys(filterObjectsByValueInPath(objects, path, true)).length;
}

function calculateCheckedEnabledAttributes(state, enabladed) {
    let count = 0;
    for (let blockKey in state.blocks) {
        if (!Object.prototype.hasOwnProperty.call(state.blocks, blockKey)) {
            continue;
        }

        if (state.blocks[blockKey].configs.action === 'delete') {
            if (state.blocks[blockKey].configs.checked && state.blocks[blockKey].configs.enabled === enabladed) {
                count += 1;
            }
            continue;
        }

        const checkedAttributes = filterObjectsByValueInPath(state.blocks[blockKey].attributes, 'checked', true);
        const enabledAttributes = filterObjectsByValueInPath(checkedAttributes, 'enabled', enabladed);
        count += Object.keys(enabledAttributes).length;
    }

    return count;
}

function hashCode(string) {
    if (string.length === 0) {
        return null;
    }

    return string.split('').reduce((a, b) => {
        a = ((a << 5) - a) + b.charCodeAt(0);
        return a & a;
    }, 0);
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
    modules: {
        provision_import,
        provision_fully_or_empty_devices,
        provision_common,
        one_device,
    },
};
