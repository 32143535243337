<template>
    <div
        v-if="isLogin"
        class="not-found-404-error-page"
    >
        <div class="not-found-404-error-page-wrapper">
            <div class="not-found-404-img-box">
                <img
                    src="/images/404.svg"
                    alt="404"
                >
            </div>
            <div class="error-details">
                <div class="error-details-title">
                    {{ $t('site', 'Oops! This page could not be found.') }}
                </div>
                <div class="error-details-description">
                    {{ $t('site', 'The page you are looking for might have been removed or is temporarily unavailable.') }}
                </div>
            </div>
            <div
                v-if="isDev"
                class="error-details-dev"
            >
                <div>Route <i>{{ $route.path }}</i> not found.</div>
                Maybe you forgot to add this route to the App.
            </div>
            <div class="not-found-404-btn">
                <button
                    class="btn btn-primary"
                    onclick="switch_page_without_cache('/admin/dashboard')"
                >
                    {{ $t('site', 'Back to main dashboard') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NotFoundView',
    data() {
        return {
            isDev: process.env.NODE_ENV === 'development',
        };
    },
    created() {
        if (!this.isLogin) {
            window.switch_page_without_cache('/admin/login');
        }
    },
    computed: {
        isLogin() {
            return !!window.spl_config.spl_user_id;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../../design/bootstrap/splynx-bootstrap-variables";

.not-found-404-error-page {
    margin: 60px auto 0;
}
.not-found-404-error-page-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    max-width: 540px;
    height: 100%;
    margin: 0 auto;
}

.not-found-404-img-box {
    padding-bottom: 30px;
}

.error-details,
.error-details-dev {
    text-align: center;
    line-height: 28px;
    font-size: 20px;
    color: $dark;
    font-family: 'Inter', sans-serif;
}

.error-details-dev {
    margin-bottom: $spacer*4;
}

.error-details .error-details-description {
    margin: 16px 0 40px 0;
    color: $secondary;
}

@media (max-width: 600px) {
    .not-found-404-error-page-wrapper {
        width: 90%;
        margin: 0 auto;
    }

    .not-found-404-img-box img {
        max-width: 100%;
    }
}
</style>
