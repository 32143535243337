import XTwigPage from '@/components/base/XTwigPage';
import MainRoutes from './config/main';
import CrmRoutes from './config/crm';
import SchedulingRoutes from './config/scheduling';
import InventoryRoutes from './config/inventory';
import FinanceRoutes from './config/finance';
import SupportRoutes from './config/support';
import NetworkingRoutes from './config/networking';
import VoiceRoutes from './config/voice';
import ModulesRoutes from './config/modules';
import ToolsRoutes from './config/tools';
import ExtendedModuleRoutes from './config/extended-module';
import TemplatesRoutes from './config/templates';

const ConfigSettings = () => import(/* webpackChunkName: "config_settings" */ '@/apps/admin/views/admin/config/settings/ConfigSettings');
const AdditionalFieldsList = () => import(/* webpackChunkName: "config_afs" */ '@/apps/admin/views/admin/config/additional-fields/AdditionalFieldsList');
const CustomTranslationsList = () => import(/* webpackChunkName: "config_translations" */ '@/apps/admin/views/admin/config/custom-translations/CustomTranslationsList');
const FileManagerList = () => import(/* webpackChunkName: "config_file_manager" */ '@/apps/admin/views/admin/config/file-manager/FileManagerList');
const HooksList = () => import(/* webpackChunkName: "config_hooks" */ '@/apps/admin/views/admin/config/integrations/hooks/HooksList');
const LabelsList = () => import(/* webpackChunkName: "config_labels" */ '@/apps/admin/views/admin/config/labels/LabelsList');

export default [
    {
        path: '',
        name: 'admin.config',
        component: ConfigSettings,
        meta: {
            breadcrumbs: [
                {
                    title: ['common', 'Config'],
                    url: '/admin/config',
                },
            ],
            title: ['common', 'Config'],
        },
    },
    {
        path: 'simple-module',
        name: 'admin.config.simple_module',
        component: XTwigPage,
    },
    {
        path: 'extended-module',
        children: ExtendedModuleRoutes,
    },
    {
        path: 'additional-fields',
        name: 'admin.config.additional_fields',
        component: AdditionalFieldsList,
        meta: {
            keepAlive: true,
            componentName: 'AdditionalFieldsList',
        },
    },
    {
        path: 'custom-translations',
        name: 'admin.config.custom_translations',
        component: CustomTranslationsList,
        meta: {
            keepAlive: true,
            componentName: 'CustomTranslationsList',
        },
    },
    {
        path: 'file-manager',
        name: 'admin.config.file_manager',
        component: FileManagerList,
        meta: {
            keepAlive: true,
            componentName: 'FileManagerList',
        },
    },
    {
        path: 'templates',
        children: TemplatesRoutes,
        meta: {
            breadcrumbs: [
                {
                    title: ['common', 'Config'],
                    url: '/admin/config',
                },
                {
                    title: ['common', 'Templates'],
                    url: '/admin/config/templates',
                },
            ],
        },
    },
    {
        path: 'labels',
        name: 'admin.config.labels',
        component: LabelsList,
        meta: {
            title: ['config', 'Labels'],
            keepAlive: true,
            componentName: 'LabelsList',
            breadcrumbs: [
                {
                    title: ['common', 'Config'],
                    url: '/admin/config',
                },
                {
                    title: ['common', 'Labels'],
                    url: '/admin/config/labels',
                },
            ],
        },
    },
    {
        path: 'company-information',
        name: 'admin.config.company_information',
        component: XTwigPage,
    },
    {
        path: 'main',
        children: MainRoutes,
    },
    {
        path: 'crm',
        children: CrmRoutes,
    },
    {
        path: 'scheduling',
        children: SchedulingRoutes,
    },
    {
        path: 'inventory',
        children: InventoryRoutes,
    },
    {
        path: 'finance',
        children: FinanceRoutes,
    },
    {
        path: 'support',
        children: SupportRoutes,
    },
    {
        path: 'networking',
        children: NetworkingRoutes,
        meta: {
            breadcrumbs: [{ title: ['common', 'Networking'], url: '/admin/config#networking' }],
        },
    },
    {
        path: 'voice',
        children: VoiceRoutes,
    },
    {
        path: 'modules',
        children: ModulesRoutes,
        meta: {
            breadcrumbs: [{ title: ['common', 'Integrations'], url: '/admin/config#integrations' }],
        },
    },
    {
        path: 'hooks',
        name: 'admin.config.hooks',
        component: HooksList,
        meta: {
            keepAlive: true,
            componentName: 'HooksList',
        },
    },
    {
        path: 'tools',
        children: ToolsRoutes,
    },
    {
        path: 'add-on',
        name: 'admin.config.add-on',
        component: XTwigPage,
    },
];
