import XTwigPage from '@/components/base/XTwigPage';

const VoicePlansList = () => import(/* webpackChunkName: "voice_plans" */ '@/apps/admin/views/admin/tariffs/VoicePlansList');
const VoicePlansAdd = () => import(/* webpackChunkName: "voice_plans" */ '@/apps/admin/views/admin/tariffs/voice/VoicePlansAdd');
const VoicePlansEdit = () => import(/* webpackChunkName: "voice_plans" */ '@/apps/admin/views/admin/tariffs/voice/VoicePlansEdit');

export default [
    {
        path: '',
        name: 'admin.tariffs.voice',
        component: VoicePlansList,
        meta: {
            breadcrumbs: [
                { title: ['common', 'Tariffs plans'], url: '/admin/tariffs/voice' },
                { title: ['common', 'Voice'], url: '/admin/tariffs/voice' },
            ],
            title: ['tariffs', 'Tariffs'],
            keepAlive: true,
            componentName: 'VoicePlansList',
        },
    },
    {
        delimiter: '--', // Use -- instead of / as path delimiter. Custom prop. Not from vue-router.
        path: 'add',
        name: 'admin.tariffs.voice.add',
        component: VoicePlansAdd,
        meta: {
            breadcrumbs: [
                { title: ['common', 'Tariffs plans'], url: '/admin/tariffs/voice' },
                { title: ['common', 'Voice'], url: '/admin/tariffs/voice' },
                { title: ['common', 'Add'], url: '/admin/tariffs/voice--add' },
            ],
            title: ['tariffs', 'Tariffs'],
        },
    },
    {
        delimiter: '--',
        path: 'edit',
        name: 'admin.tariffs.voice.edit',
        component: VoicePlansEdit,
        meta: {
            breadcrumbs: [
                { title: ['common', 'Tariffs plans'], url: '/admin/tariffs/voice' },
                { title: ['common', 'Voice'], url: '/admin/tariffs/voice' },
            ],
            title: ['tariffs', 'Tariffs'],
        },
    },
    {
        delimiter: '--',
        path: 'change-tariff-on-services',
        name: 'admin.tariffs.voice.change_tariff_on_services',
        component: XTwigPage,
    },
    {
        delimiter: '--',
        path: 'table',
        name: 'admin.tariffs.voice.table',
        component: XTwigPage,
        meta: {
            title: ['tariffs', 'Tariff plan table'],
        },
    },
];
