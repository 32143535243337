export const SET_ADMIN = 'SET_ADMIN';
export const SET_HEADER_SHORTCUTS = 'SET_HEADER_SHORTCUTS';
export const SET_LOGO = 'SET_LOGO';
export const SET_FOOTER = 'SET_FOOTER';

export const SET_SEARCH_ENTITIES = 'SET_SEARCH_ENTITIES';
export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';
export const SET_SEARCH_ACTIVE_POSITION = 'SET_SEARCH_ACTIVE_POSITION';
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';

export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';
export const REFRESH_ROUTE = 'REFRESH_ROUTE';
export const SET_DOCUMENTATION_CONFIGS = 'SET_DOCUMENTATION_CONFIGS';
export const SET_SHOW_NOT_FOUND_PAGE = 'SET_SHOW_NOT_FOUND_PAGE';

export const SET_MENU = 'SET_MENU';

export const CHANGE_NOTIFICATIONS_LIST = 'CLEAR_NOTIFICATIONS_LIST';
export const ADD_NOTIFICATION_TO_LIST = 'ADD_NOTIFICATION_TO_LIST';

export const SIDEBAR_LOAD = 'SIDEBAR_LOAD';
export const SIDEBAR_SET_OPTIONS = 'SIDEBAR_SET_OPTIONS';

export const SET_ENTRY_POINTS = 'SET_ENTRY_POINTS';
export const SET_ENTRY_POINTS_INFO = 'SET_ENTRY_POINTS_INFO';
export const DELETE_ENTRY_POINT = 'DELETE_ENTRY_POINT';
export const ADD_ENTRY_POINT = 'ADD_ENTRY_POINT';
export const SET_INSTALL_MODULE_INFO = 'SET_INSTALL_MODULE_INFO';

export const SET_LOCATIONS = 'SET_LOCATIONS';
export const SET_NUMBER_CONFIG = 'SET_NUMBER_CONFIG';
export const SET_PARTNERS = 'SET_PARTNERS';

export const SET_SERVER_SIDE_CONFIG = 'SET_SERVER_SIDE_CONFIG';

export const SET_LICENSE = 'SET_LICENSE';

export const SET_NEED_UPDATE_STATE = 'SET_NEED_UPDATE_STATE';
export const SET_LEADS = 'SET_LEADS';
export const SET_BOARD_LEADS = 'SET_BOARD_LEADS';
export const SET_STAGES = 'SET_STAGES';
export const SET_FILTERED_LEADS = 'SET_FILTERED_LEADS';
export const SET_BOARD_FILTERED_LEADS = 'SET_BOARD_FILTERED_LEADS';
export const RESET_FILTERED_LEADS = 'RESET_FILTERED_LEADS';
export const MOVE_LEAD = 'MOVE_LEAD';
export const SET_LEAD_INFO = 'SET_LEAD_INFO';
export const DELETE_LEAD = 'DELETE_LEAD';
export const UPDATE_LEAD = 'UPDATE_LEAD';

export const SET_BOARD_TASKS = 'SET_BOARD_TASKS';
export const SET_TASKS = 'SET_TASKS';
export const SET_FILTERED_TASKS = 'SET_FILTERED_TASKS';
export const SET_BOARD_FILTERED_TASKS = 'SET_BOARD_FILTERED_TASKS';
export const RESET_FILTERED_TASKS = 'RESET_FILTERED_TASKS';
export const MOVE_TASK = 'MOVE_TASK';
export const SET_TASK_INFO = 'SET_TASK_INFO';
export const SET_PROJECT_NAME = 'SET_PROJECT_NAME';
export const DELETE_TASK = 'DELETE_TASK';
export const UPDATE_TASK = 'UPDATE_TASK';

export const SET_INTERNET_PAGE_INFO = 'SET_INTERNET_PAGE_INFO';
export const SET_VOICE_TARIFFS_PAGE_INFO = 'SET_VOICE_TARIFFS_PAGE_INFO';
export const SET_INTERNET_PAGE_TARIFFS_FOR_CHANGE = 'SET_INTERNET_PAGE_TARIFFS_FOR_CHANGE';
export const SET_VOICE_PAGE_TARIFFS_FOR_CHANGE = 'SET_VOICE_PAGE_TARIFFS_FOR_CHANGE';

export const SET_INVOICE_MODAL_INFO = 'SET_INVOICE_MODAL_INFO';
export const CLEAR_INVOICE_MODAL_INFO = 'CLEAR_INVOICE_MODAL_INFO';

// Customers
export const SET_CUSTOMER_INFO = 'SET_CUSTOMER_INFO';
export const SET_CUSTOMER_BILLING_INFO = 'SET_CUSTOMER_BILLING_INFO';
export const SET_CUSTOMER_BLOCKING_INFO = 'SET_CUSTOMER_BLOCKING_INFO';

// Customers statistics
export const STATISTICS_RESET_SESSION_RECORD = 'STATISTICS_RESET_SESSION_RECORD';
export const STATISTICS_SET_SESSION_RECORD = 'STATISTICS_SET_SESSION_RECORD';
export const STATISTICS_SET_SESSION_RECORD_ATTRIBUTES_META = 'STATISTICS_SET_SESSION_RECORD_ATTRIBUTES_META';

export const SET_CREDIT_NOTE_MODAL_INFO = 'SET_CREDIT_NOTE_MODAL_INFO';
export const CLEAR_CREDIT_NOTE_MODAL_INFO = 'CLEAR_CREDIT_NOTE_MODAL_INFO';

// ACS provisioning flow mutations
export const ACS_PROVISIONING_LOAD_SET_GROUP_DATA = 'ACS_PROVISIONING_LOAD_SET_GROUP_DATA';
export const ACS_PROVISIONING_UPDATE_OBJECT_KEY = 'ACS_PROVISIONING_UPDATE_OBJECT_KEY';
export const ACS_PROVISIONING_UPDATE_OBJECT_ACTION = 'ACS_PROVISIONING_UPDATE_OBJECT_ACTION';
export const ACS_PROVISIONING_ADD_NEW_ATTRIBUTE = 'ACS_PROVISIONING_ADD_NEW_ATTRIBUTE';
export const ACS_PROVISIONING_MOVE_OBJECT = 'ACS_PROVISIONING_MOVE_OBJECT';
export const ACS_PROVISIONING_REMOVE_ATTRIBUTE = 'ACS_PROVISIONING_REMOVE_ATTRIBUTE';
export const ACS_PROVISIONING_RECALCULATE_POSITIONS = 'ACS_PROVISIONING_RECALCULATE_POSITIONS';
export const ACS_PROVISIONING_RECALCULATE_POSITIONS_BELOW_ATTRIBUTE = 'ACS_PROVISIONING_RECALCULATE_POSITIONS_BELOW_ATTRIBUTE';
export const ACS_PROVISIONING_SET_ADDING_NEW_ATTRIBUTE_STATE = 'ACS_PROVISIONING_SET_ADDING_NEW_ATTRIBUTE_STATE';
export const ACS_PROVISIONING_ADD_NEW_BLOCK = 'ACS_PROVISIONING_ADD_NEW_BLOCK';
export const ACS_PROVISIONING_REMOVE_BLOCK = 'ACS_PROVISIONING_REMOVE_BLOCK';
export const ACS_PROVISIONING_RECALCULATE_POSITIONS_BELOW_OBJECT = 'ACS_PROVISIONING_RECALCULATE_POSITIONS_BELOW_OBJECT';
export const ACS_PROVISIONING_SET_ADD_NEW_OBJECT_STATE = 'ACS_PROVISIONING_SET_ADD_NEW_OBJECT_STATE';
export const ACS_PROVISIONING_SET_ATTRIBUTE_CHECKED_STATUS = 'ACS_PROVISIONING_SET_ATTRIBUTE_CHECKED_STATUS';
export const ACS_PROVISIONING_SET_ATTRIBUTE_NEW_VALUE = 'ACS_PROVISIONING_SET_ATTRIBUTE_NEW_VALUE';
export const ACS_PROVISIONING_SET_OBJECT_CHECKED_STATUS = 'ACS_PROVISIONING_SET_OBJECT_CHECKED_STATUS';
export const ACS_PROVISIONING_SET_ALL_OBJECT_CHECKED_STATUS = 'ACS_PROVISIONING_SET_ALL_OBJECT_CHECKED_STATUS';
export const ACS_PROVISIONING_SET_DISABLED_STATE_FOR_OBJECT = 'ACS_PROVISIONING_SET_DISABLED_STATE_FOR_OBJECT';
export const ACS_PROVISIONING_SET_DISABLED_STATE_FOR_ATTRIBUTE = 'ACS_PROVISIONING_SET_DISABLED_STATE_FOR_ATTRIBUTE';
export const ACS_PROVISIONING_SET_FILLED = 'ACS_PROVISIONING_SET_FILLED';
export const ACS_PROVISIONING_SET_VALUE_BY_SELECTED = 'ACS_PROVISIONING_SET_VALUE_BY_SELECTED';
export const ACS_PROVISIONING_ADD_AVAILABLE_ATTRIBUTE_INTO_STORAGE = 'ACS_PROVISIONING_ADD_AVAILABLE_ATTRIBUTE_INTO_STORAGE';
export const ACS_PROVISIONING_REMOVE_AVAILABLE_ATTRIBUTE_IN_STORAGE = 'ACS_PROVISIONING_REMOVE_AVAILABLE_ATTRIBUTE_IN_STORAGE';
export const ACS_PROVISIONING_SET_ACTIVE_BLOCK_KEY = 'ACS_PROVISIONING_SET_ACTIVE_BLOCK_KEY';
export const ACS_PROVISIONING_SET_ACTIVE_BLOCK_SELECTED_KEY = 'ACS_PROVISIONING_SET_ACTIVE_BLOCK_SELECTED_KEY,';

// ACS provisioning flow import mutations
export const ACS_PROVISIONING_INIT_COMPONENT = 'ACS_PROVISIONING_INIT_COMPONENT';

// ACS provisioning flow device refresh loading mutations
export const ACS_PROVISIONING_SET_AVAILABLE_DEVICES_FOR_REFRESH = 'ACS_PROVISIONING_SET_AVAILABLE_DEVICES_FOR_REFRESH';

export const SET_PROFORMA_INVOICE_MODAL_INFO = 'SET_PROFORMA_INVOICE_MODAL_INFO';
export const CLEAR_PROFORMA_INVOICE_MODAL_INFO = 'CLEAR_PROFORMA_INVOICE_MODAL_INFO';

export const SET_TEMPLATES_DATA = 'SET_TEMPLATES_DATA';
export const SET_TEMPLATES_ADD_PAGE_DATA = 'SET_TEMPLATES_ADD_PAGE_DATA';

export const SET_LABELS_DATA = 'SET_LABELS_DATA';
export const SET_LABELS_ADD_PAGE_DATA = 'SET_LABELS_ADD_PAGE_DATA';

// Inventory
export const INVENTORY_ITEMS_SELL_INIT = 'INVENTORY_ITEMS_SELL_INIT';
export const INVENTORY_ITEMS_SELL_SET_FINANCE_ITEMS = 'INVENTORY_ITEMS_SELL_SET_FINANCE_ITEMS';
export const INVENTORY_ITEMS_SELL_SET_TOTALS = 'INVENTORY_ITEMS_SELL_SET_TOTALS';
export const INVENTORY_ITEMS_SELL_UPDATE_ITEM = 'INVENTORY_ITEMS_SELL_UPDATE_ITEM';
export const INVENTORY_ITEMS_SELL_DELETE_ITEM = 'INVENTORY_ITEMS_SELL_DELETE_ITEM';
export const INVENTORY_ITEMS_SELL_UPDATE_ROW_STATE = 'INVENTORY_ITEMS_SELL_UPDATE_ROW_STATE';
export const INVENTORY_ITEMS_SELL_SET_ITEMS = 'INVENTORY_ITEMS_SELL_SET_ITEMS';
export const INVENTORY_ITEMS_SELL_SET_IDS = 'INVENTORY_ITEMS_SELL_SET_IDS';

export const SET_PAYMENT_MODAL_INFO = 'SET_PAYMENT_MODAL_INFO';
export const CLEAR_PAYMENT_MODAL_INFO = 'CLEAR_PAYMENT_MODAL_INFO';

export const SET_CUSTOMER_STATEMENT_INFO = 'SET_CUSTOMER_STATEMENT_INFO';
export const CLEAR_CUSTOMER_STATEMENT_INFO = 'CLEAR_CUSTOMER_STATEMENT_INFO';

export const SET_TRANSACTION_MODAL_INFO = 'SET_TRANSACTION_MODAL_INFO';
export const CLEAR_TRANSACTION_MODAL_INFO = 'CLEAR_TRANSACTION_MODAL_INFO';

export const SET_RECURRING_INVOICE_MODAL_INFO = 'SET_RECURRING_INVOICE_MODAL_INFO';
export const CLEAR_RECURRING_INVOICE_MODAL_INFO = 'CLEAR_RECURRING_INVOICE_MODAL_INFO';

export const SET_FINANCE_MASS_PAY = 'SET_FINANCE_MASS_PAY';
export const CLEAR_FINANCE_MASS_PAY = 'CLEAR_FINANCE_MASS_PAY';

export const SET_HISTORY_AND_PREVIEW_PAGE_DATA = 'SET_HISTORY_AND_PREVIEW_PAGE_DATA';
export const CONFIG_SETTINGS_TYPE_PAGE = 'CONFIG_SETTINGS_TYPE_PAGE';
export const CONFIG_SETTINGS_STORAGE = 'CONFIG_SETTINGS_STORAGE';
export const CONFIG_SETTINGS_SEARCH_QUERY = 'CONFIG_SETTINGS_SEARCH_QUERY';
export const CONFIG_SETTINGS_BLOCKS = 'CONFIG_SETTINGS_BLOCKS';
export const CONFIG_SETTINGS_QUERY_RESULT = 'CONFIG_SETTINGS_QUERY_RESULT';
export const CONFIG_SETTINGS_FILTER_LIST = 'CONFIG_SETTINGS_FILTER_LIST';
export const CONFIG_SETTINGS_ACTIVE_FILTER_TITLES = 'CONFIG_SETTINGS_ACTIVE_FILTER_TITLES';
export const CONFIG_SETTINGS_BASIC_STATE = 'CONFIG_SETTINGS_BASIC_STATE';
export const CONFIG_SETTINGS_BASIC_TRANSLATE_BASIC = 'CONFIG_SETTINGS_BASIC_TRANSLATE_BASIC';
export const CONFIG_SETTINGS_BASIC_TRANSLATE_ALL = 'CONFIG_SETTINGS_BASIC_TRANSLATE_ALL';
export const CONFIG_SETTINGS_BASIC_ACTIVE_TITLES = 'CONFIG_SETTINGS_BASIC_ACTIVE_TITLES';
export const CONFIG_SETTINGS_FILTER_DEFAULT_SHORTCUTS = 'CONFIG_SETTINGS_FILTER_DEFAULT_SHORTCUTS';
export const SET_OVERVIEW_EDIT_TO_BILL_DATE = 'SET_OVERVIEW_EDIT_TO_BILL_DATE';
export const CLEAR_OVERVIEW_EDIT_TO_BILL_DATE = 'CLEAR_OVERVIEW_EDIT_TO_BILL_DATE';
export const SET_PREVIEW_REMINDER = 'SET_PREVIEW_REMINDER';
export const CLEAR_PREVIEW_REMINDER = 'CLEAR_PREVIEW_REMINDER';

export const SET_OVERVIEW_TAB_INFO = 'SET_OVERVIEW_TAB_INFO';
export const CLEAR_OVERVIEW_TAB_INFO = 'CLEAR_OVERVIEW_TAB_INFO';
export const CHANGE_FUTURE_BLOCK_OVERVIEW_TAB_INFO = 'CHANGE_FUTURE_BLOCK_OVERVIEW_TAB_INFO';

export const SET_ON_BOARDING_STEPS = 'SET_ON_BOARDING_STEPS';
export const ON_BOARDING_LOAD = 'ON_BOARDING_LOAD';
export const ON_BOARDING_PAGE_BILLING_FINANCE_DOCUMENTS_LOAD = 'ON_BOARDING_PAGE_BILLING_FINANCE_DOCUMENTS_LOAD';
export const ON_BOARDING_MAIN_DASHBOARD_LOAD = 'ON_BOARDING_MAIN_DASHBOARD_LOAD';
export const ON_BOARDING_STEPS = 'ON_BOARDING_STEPS';
export const ON_BOARDING_CURRENT_STEP = 'ON_BOARDING_CURRENT_STEP';
export const ON_BOARDING_REFS = 'ON_BOARDING_REFS';
export const SET_ON_BOARDING_PERMISSIONS = 'SET_ON_BOARDING_PERMISSIONS';

// Reference keepers
export const SET_APP_CHILD_REFERENCES = 'SET_APP_CHILD_REFERENCES';

// Scheduling map
export const SET_SCHEDULING_MAP_INFO = 'SET_SCHEDULING_MAP_INFO';
export const SET_SCHEDULING_MAP_MARKERS = 'SET_SCHEDULING_MAP_MARKERS';

// Reports
export const SET_HOTSPOT_REPORT_INFO = 'SET_HOTSPOT_REPORT_INFO';
export const CLEAR_HOTSPOT_REPORT_INFO = 'CLEAR_HOTSPOT_REPORT_INFO';

// Administration Splynx license
export const LOAD_LICENSE_CUSTOMERS_USAGE_MODAL_DATA = 'LOAD_LICENSE_CUSTOMERS_USAGE_MODAL_DATA';

// /admin/config/main/cap
export const SET_CAP_CONFIGS_PAGE_DATA = 'SET_CAP_CONFIGS_PAGE_DATA';

// /admin/config/main/portal
export const SET_PORTAL_CONFIGS_PAGE_DATA = 'SET_PORTAL_CONFIGS_PAGE_DATA';

export const SET_INCOMING_MAILBOXES_PAGE_DATA = 'SET_INCOMING_MAILBOXES_PAGE_DATA';

export const SET_GOOGLE_OAUTH_PAGE_DATA = 'SET_GOOGLE_OAUTH_PAGE_DATA';

export const SET_MICROSOFT_OAUTH_PAGE_DATA = 'SET_MICROSOFT_OAUTH_PAGE_DATA';

export const SET_ADMIN_PROFILE_PAGE_DATA = 'SET_ADMIN_PROFILE_PAGE_DATA';

export const SET_ADMIN_PROFILE_PHOTO = 'SET_ADMIN_PROFILE_PHOTO';

export const SET_ADMIN_IMAP_PAGE_DATA = 'SET_ADMIN_IMAP_PAGE_DATA';

export const SET_ADMIN_CUSTOMER_SEARCH_PAGE_DATA = 'SET_ADMIN_CUSTOMER_SEARCH_PAGE_DATA';

// Logout
export const SET_LOGOUT_TIMEOUT = 'SET_LOGOUT_TIMEOUT';

export const SET_ADMIN_SCHEDULING_PAGE_DATA = 'SET_ADMIN_SCHEDULING_PAGE_DATA';
export const SET_ADMIN_PAIRED_CALENDARS_MODAL_DATA = 'SET_ADMIN_PAIRED_CALENDARS_MODAL_DATA';
export const SET_ADMIN_PAIRED_CALENDARS_DELETE_MODAL_DATA = 'SET_ADMIN_PAIRED_CALENDARS_DELETE_MODAL_DATA';
